import { writable } from 'svelte/store';

export type TopBarButton = {
  label: string;
  onClick: () => void;
};

type TopBarStore = {
  backButtonLabel: string | undefined;
  button: TopBarButton | undefined;
  context: string;
  showBackButton: boolean;
  title: string;
};

const createTopBarStore = () => {
  const initialState: TopBarStore = {
    button: undefined,
    context: '',
    showBackButton: false,
    backButtonLabel: undefined,
    title: 'MPay',
  };
  const { set, subscribe, update } = writable(initialState);

  return {
    reset: () => set(initialState),
    setButton: (value: TopBarButton) =>
      update((state) => ({ ...state, button: value })),
    setContext: (value: string) =>
      update((state) => ({ ...state, context: value })),
    setShowBackButton: (value: boolean | string) => {
      if (typeof value === 'boolean') {
        update((state) => ({ ...state, showBackButton: value }));
      } else {
        update((state) => ({
          ...state,
          showBackButton: true,
          backButtonLabel: value,
        }));
      }
    },
    setTitle: (value: string) =>
      update((state) => ({ ...state, title: value })),
    subscribe,
  };
};

export const topBar = createTopBarStore();
