import {
  endOfMonth,
  endOfQuarter,
  endOfYear,
  format,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  subMonths,
  subYears,
} from 'date-fns';
import { DateRange } from '../constants';

// calculate date range for different time periods
export const calculateDateRange = (interval: DateRange) => {
  const today = new Date();

  switch (interval) {
    case DateRange.LAST_MONTH: {
      const lastMonth = subMonths(today, 1);
      return {
        endDate: endOfMonth(lastMonth),
        startDate: startOfMonth(lastMonth),
      };
    }
    case DateRange.LAST_YEAR: {
      const lastYear = subYears(today, 1);
      return {
        endDate: endOfYear(lastYear),
        startDate: startOfYear(lastYear),
      };
    }
    case DateRange.THIS_AND_LAST_YEAR: {
      const lastYear = subYears(today, 1);
      return {
        endDate: endOfYear(today),
        startDate: startOfYear(lastYear),
      };
    }
    case DateRange.THIS_MONTH:
      return {
        endDate: endOfMonth(today),
        startDate: startOfMonth(today),
      };
    case DateRange.THIS_QUARTER:
      return {
        endDate: endOfQuarter(today),
        startDate: startOfQuarter(today),
      };
    case DateRange.THIS_YEAR:
      return {
        endDate: endOfYear(today),
        startDate: startOfYear(today),
      };
    default:
      throw new Error('Invalid date range interval');
  }
};

// format date in query param format
export const formatDate = (date: Date) => {
  return format(date, 'yyyy-MM-dd');
};
