import type { DateArg, Locale } from 'date-fns';
import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { enUS, es, it, ro } from 'date-fns/locale';
import { locale } from 'svelte-i18n';
import { get } from 'svelte/store';
import { locales } from '../../i18n';
import { getLocaleFromCountryCode } from '../../i18n';
import { isNumber } from './helpers';

// Map locale codes to date-fns locale objects
const localeMap: Record<string, Locale> = {
  en: enUS,
  es: es,
  it: it,
  ro: ro,
};

/**
 * Gets the appropriate date-fns locale based on the current application locale
 */
export function getDateFnsLocale(): Locale {
  const currentLocale = get(locale) || locales.en.dateLocale;
  return localeMap[currentLocale] || enUS;
}

export const getFormattedISO8601DateLocal = (date: DateArg<Date>) => {
  return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", {
    locale: getDateFnsLocale(),
  });
};

const getFormattedDisplayDateLocal = (date: DateArg<Date>) => {
  const formatString = 'dd.MM.yyyy, HH:mm';
  return format(date, formatString, {
    locale: getDateFnsLocale(),
  });
};

const getFormattedDisplayDateUtc = (date: DateArg<Date>) => {
  const formatString = 'dd.MM.yyyy, HH:mm';
  return formatInTimeZone(date, 'UTC', formatString, {
    locale: getDateFnsLocale(),
  });
};

export const getDisplayDate = (
  date: DateArg<Date>,
  useLocalTime: boolean = false
) => {
  return useLocalTime
    ? getFormattedDisplayDateLocal(date)
    : getFormattedDisplayDateUtc(date);
};

export const getFormattedNumber = (
  value: number | undefined,
  minimumFractionDigits: number = 2
) => {
  if (!isNumber(value)) return;

  if (minimumFractionDigits === 0) {
    const fractionPart = value.toString().split('.')[1];
    if (fractionPart) {
      minimumFractionDigits = 2;
    }
  }

  return value.toLocaleString(getLocaleFromCountryCode(), {
    minimumFractionDigits,
  });
};

export const getQueryDateFormat = (date: Date | string) => {
  if (!date) return;

  return format(date, 'dd.MM.yyyy', {
    locale: getDateFnsLocale(),
  });
};
