import './app.css';
import { locale } from 'svelte-i18n';
import App from './App.svelte';
import { getLocale } from './i18n';
import './i18n';
import { showLoader } from './lib/JSBridge/loaderController';
import { registerGlobalNavigation } from './lib/JSBridge/navigationController';
import { initDebuggingTools } from './lib/utils/debuggingTools';
import { initFeatureFlags } from './lib/utils/featureFlags';
import { initializePostHog } from './plugins/posthog';
import { initializeSentry } from './plugins/sentry';

function createApp(props: Record<string, unknown>) {
  new App({
    props,
    target: document.getElementById('app')!,
  });
}

const init = async () => {
  showLoader();
  registerGlobalNavigation();

  await initializePostHog();
  await initFeatureFlags();
  if (import.meta.env.MODE !== 'test') initializeSentry();

  locale.set(getLocale());

  if (import.meta.env.MODE !== 'production') {
    initDebuggingTools();
  }

  createApp({});
};

init();
