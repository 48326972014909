import {
  addMonths,
  endOfMonth,
  getDate,
  getMonth,
  getYear,
  isDate,
  parseISO,
  setDate,
  startOfMonth,
  subMonths,
} from 'date-fns';
import type { MonthlyTransactions, Transactions } from '../../types/mpay.types';
import { CreditSettleFrequency } from '../constants';
import { getQueryDateFormat } from './formatters';

interface DateParts {
  day: number;
  month: number;
  year: number;
}

/**
 * Ensures the input is a Date object
 */
function ensureDate(dateInput: Date | number | string): Date {
  if (isDate(dateInput)) {
    return dateInput as Date;
  }

  return typeof dateInput === 'string'
    ? parseISO(dateInput)
    : new Date(dateInput);
}

/**
 * Formats a date range as a string
 */
function formatRange(start: Date, end: Date): string {
  return `${getQueryDateFormat(start)} - ${getQueryDateFormat(end)}`;
}

/**
 * Gets the 24th to 23rd month range string for a date
 */
function get24To23Month(date: Date): string {
  const CUTOFF_DAY = 24;
  const { month, year } = getDates(date);
  const currentDay = getDate(date);

  let start: Date;
  let end: Date;

  if (currentDay >= CUTOFF_DAY) {
    // If we're on or after the 24th, period starts now and ends next month
    start = setDate(new Date(year, month), CUTOFF_DAY);
    end = setDate(addMonths(new Date(year, month), 1), CUTOFF_DAY - 1);
  } else {
    // If we're before the 24th, period started last month and ends this month
    start = setDate(subMonths(new Date(year, month), 1), CUTOFF_DAY);
    end = setDate(new Date(year, month), CUTOFF_DAY - 1);
  }

  return formatRange(start, end);
}

/**
 * Extracts day, month, and year parts from a date
 */
function getDates(date: Date): DateParts {
  return {
    day: getDate(date),
    month: getMonth(date),
    year: getYear(date),
  };
}

/**
 * Gets half-month ranges (1-15 or 16-end of month)
 */
function getHalfMonths(date: Date): string {
  const CUTOFF_DAY = 15;
  const { day, month, year } = getDates(date);
  const lastDayOfTheMonth = getDate(endOfMonth(new Date(year, month)));

  const start = setDate(new Date(year, month), day <= CUTOFF_DAY ? 1 : 16);
  const end = setDate(
    new Date(year, month),
    day <= CUTOFF_DAY ? CUTOFF_DAY : lastDayOfTheMonth
  );

  return formatRange(start, end);
}

/**
 * Gets a full month range
 */
function getMonthRange(date: Date): string {
  const { month, year } = getDates(date);

  const start = startOfMonth(new Date(year, month));
  const end = endOfMonth(new Date(year, month));

  return formatRange(start, end);
}

/**
 * Groups transactions by different date ranges based on the credit settlement frequency
 */
export function groupInvoices(
  data: Transactions,
  groupBy: CreditSettleFrequency
): MonthlyTransactions {
  const groups: MonthlyTransactions = {};

  data.forEach((entry) => {
    if (!entry.date) {
      throw new Error('Transaction date cannot be null or undefined');
    }

    const date = ensureDate(entry.date);
    let groupKey: string;

    switch (groupBy) {
      case CreditSettleFrequency.MEDIU14:
      case CreditSettleFrequency.MEDIU21:
      case CreditSettleFrequency.MEDIU26:
        groupKey = getHalfMonths(date);
        break;

      case CreditSettleFrequency.MEDIU20:
        groupKey = get24To23Month(date);
        break;

      case CreditSettleFrequency.FIX15:
      case CreditSettleFrequency.FIX30:
      case CreditSettleFrequency.MEDIU30:
      case CreditSettleFrequency.MEDIU45:
      default:
        // all the other possible "FIX XX" will default to a month
        groupKey = getMonthRange(date);
        break;
    }

    // initialize group array if not exists
    if (!groups[groupKey]) {
      groups[groupKey] = [];
    }

    groups[groupKey].push(entry);
  });

  return groups;
}
