import { TARGET } from '../../env';
import {
  idamAuthMappings,
  type IdamSupportedCountryCode,
  idamSupporttedCountryCodes,
} from './idamAuthMappings';
import { routeBuilders } from './routeBuilders';

const idamAuthTargetMappings = idamAuthMappings[TARGET];

export const ensureIdamSupportsCountryCode = (
  countryCode?: string
): IdamSupportedCountryCode | undefined => {
  if (!countryCode) return undefined;
  const countryCodeUpper = countryCode.toUpperCase();
  if (
    !idamSupporttedCountryCodes.includes(
      countryCodeUpper as IdamSupportedCountryCode
    )
  ) {
    return undefined;
  }

  return countryCodeUpper as IdamSupportedCountryCode;
};

export const getIdamUrl = (
  countryCode: IdamSupportedCountryCode,
  accountId: string,
  returnPath: string
): string => {
  const idamAuth = idamAuthTargetMappings[countryCode];

  const nonce = crypto.randomUUID();
  const baseUrl = `https://${idamAuth.AUTH_HOST}${idamAuth.AUTH_PATH}`;

  const redirect_uri = new URL(
    routeBuilders.authenticate({ '*': returnPath }).replace(/\/+/g, '/'),
    window.location.origin
  ).toString();

  const queryParams = {
    client_id: idamAuth.CLIENT_ID,
    nonce,
    realm_id: idamAuth.REALM_ID,
    redirect_uri,
    response_type: idamAuth.RESPONSE_TYPE,
    scope: idamAuth.SCOPE,
    state: `${accountId}`,
    user_type: idamAuth.USER_TYPE,
  };

  return `${baseUrl}?${new URLSearchParams(queryParams)}`;
};
