import { TARGET } from '../../env';

interface IAppLocation {
  replace(url: string): void;
}

declare global {
  interface Window {
    AppLocation?: IAppLocation;
  }
}

export const AppLocation = {
  replace(url: string) {
    if (TARGET === 'test' && window.AppLocation) {
      window.AppLocation.replace(url);
    } else {
      window.location.replace(url);
    }
  },
};
