<script lang="ts">
  export let color: string;
  export let size: string;
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  width={size}
  height={size}
  viewBox="0 0 20 20"
  fill="none"
>
  <g clip-path="url(#clip0_13383_5993)">
    <path
      d="M10 0C15.5137 0 20 4.48625 20 10C20 15.5137 15.5137 20 10 20C4.48625 20 0 15.5137 0 10C0 4.48625 4.48625 0 10 0ZM10 1.25C5.175 1.25 1.25 5.175 1.25 10C1.25 14.825 5.175 18.75 10 18.75C14.825 18.75 18.75 14.825 18.75 10C18.75 5.175 14.825 1.25 10 1.25ZM10 8.125C10.345 8.125 10.625 8.40438 10.625 8.75V14.375H12.5C12.8456 14.375 13.125 14.655 13.125 15C13.125 15.345 12.8456 15.625 12.5 15.625H7.5C7.15437 15.625 6.875 15.345 6.875 15C6.875 14.655 7.15437 14.375 7.5 14.375H9.375V9.375H8.125C7.77937 9.375 7.5 9.095 7.5 8.75C7.5 8.40438 7.77937 8.125 8.125 8.125H10ZM10 4.375C10.6892 4.375 11.25 4.93583 11.25 5.625C11.25 6.31417 10.6892 6.875 10 6.875C9.31083 6.875 8.75 6.31417 8.75 5.625C8.75 4.93583 9.31083 4.375 10 4.375Z"
      fill={color}
    />
  </g>
</svg>
