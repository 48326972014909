<script lang="ts">
  import type {
    Invoice,
    MonthlyInvoices,
    MonthlyTransactions,
    Repayment,
  } from '../../types/mpay.types';
  import { topBar } from '../../store/TopBarStore';
  import { Target } from '../utils/analytics/keys';
  import { getNextRoute } from '../utils/helpers';
  import { isInvoiceType, isRepaymentType } from '../utils/invoices';
  import { routeBuilders } from '../utils/routeBuilders';
  import { EventsMapKeys, trackEvents } from '../utils/tracking';
  import InvoicesListTimePeriod from './InvoicesListTimePeriod.svelte';
  import ListInvoice from './ListInvoice.svelte';
  import RepaymentListItem from './RepaymentListItem.svelte';

  export let transactions: MonthlyInvoices | MonthlyTransactions;
  export let index: number;
  export let id: string;
  export let timePeriod: string;
  export let isMcredit = false;

  function track(transaction: Invoice | Repayment) {
    trackEvents(EventsMapKeys.TRANSACTION_CLICK, {
      context: $topBar.context,
      target: isInvoiceType(transaction)
        ? Target.InvoiceListItem
        : Target.RepaymentListItem,
    });
  }
</script>

<InvoicesListTimePeriod
  {timePeriod}
  isFirstEntry={Boolean(index === 0 && transactions[timePeriod].length)}
  {isMcredit}
/>

<ul data-cy={id}>
  {#each transactions[timePeriod] as transaction}
    {#if isRepaymentType(transaction)}
      <RepaymentListItem
        repayment={transaction}
        href={routeBuilders.repaymentDetails({ id: transaction.id })}
        onClick={() => track(transaction)}
      />
    {:else if isInvoiceType(transaction)}
      <ListInvoice
        invoice={transaction}
        href={getNextRoute(transaction)}
        onClick={() => track(transaction)}
        isMcreditList={isMcredit}
      />
    {/if}
  {/each}
</ul>
