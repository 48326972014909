import type { Env, EnvTarget } from './types/env.types';
import localEnv from './env.local';
import ppEnv from './env.pp';
import prodEnv from './env.prod';

type EnvWithTarget = Env & { TARGET: EnvTarget };

let envVariables: EnvWithTarget;
const hostnameForConfig = window?.location?.hostname;

if (typeof window !== 'undefined' && window.Cypress !== undefined) {
  envVariables = {
    TARGET: 'test',
    ...localEnv,
  };
} else if (/^localhost/.test(hostnameForConfig)) {
  envVariables = {
    TARGET: 'local',
    ...localEnv,
  };
} else if (
  /^companion\.pp\.metro-fs\.com|^mfs-companion-next|^mfs-companion-pp/.test(
    hostnameForConfig
  )
) {
  envVariables = {
    TARGET: 'pp',
    ...ppEnv,
  };
} else {
  envVariables = {
    TARGET: 'prod',
    ...prodEnv,
  };
}

export default envVariables;

export const {
  MPAY_API_BASE_URI,
  MPAY_ENV,
  MPAY_POSTHOG_API_HOST,
  MPAY_POSTHOG_TOKEN,
  MPAY_SENTRY_DSN,
  TARGET,
} = envVariables;
