import { ACCOUNT_ID_ITEM, COUNTRY_CODE_ITEM, TOKEN_ITEM } from '../constants';

export const storeAccountId = (accountId: string) =>
  localStorage.setItem(ACCOUNT_ID_ITEM, accountId);
export const storeToken = (token: string) =>
  localStorage.setItem(TOKEN_ITEM, token);
export const getStoredAccountId = () => localStorage.getItem(ACCOUNT_ID_ITEM);

export const getStoredToken = () => localStorage.getItem(TOKEN_ITEM);
export const removeStoredAccountId = () =>
  localStorage.removeItem(ACCOUNT_ID_ITEM);
export const removeStoredToken = () => localStorage.removeItem(TOKEN_ITEM);

export const getStoredCountryCode = () =>
  localStorage.getItem(COUNTRY_CODE_ITEM);
export const storeCountryCode = (countryCode: string) =>
  localStorage.setItem(COUNTRY_CODE_ITEM, countryCode);
export const removeStoredCountryCode = () =>
  localStorage.removeItem(COUNTRY_CODE_ITEM);
