<script lang="ts">
  import { t } from 'svelte-i18n';
  import { featureFlagsStore } from '../../store/featureFlagsStore';
  import { mCreditStore } from '../../store/mCreditStore';
  import { getFormattedNumber, getQueryDateFormat } from '../utils/formatters';
  import MCreditDueAmountSection from './MCreditDueAmountSection.svelte';
</script>

<section
  class="flex flex-col justify-between {$featureFlagsStore.due_overdue_amount &&
  $featureFlagsStore.debit_period_next_due_date
    ? 'h-36'
    : 'h-16'}"
  data-cy="mcredit-due-amount"
>
  {#if $featureFlagsStore.due_overdue_amount}
    <MCreditDueAmountSection
      titleLeft={$t('mcredit.due_amount')}
      valueLeft={getFormattedNumber($mCreditStore.mCreditSummary.dueAmount, 0)}
      titleRight={$t('mcredit.overdue_amount')}
      valueRight={getFormattedNumber(
        $mCreditStore.mCreditSummary.overdueAmount,
        0
      )}
      isTextRed={$mCreditStore.mCreditSummary.overdueAmount > 0}
      showCurrency
    />
  {/if}

  {#if $featureFlagsStore.due_overdue_amount && $featureFlagsStore.debit_period_next_due_date}
    <hr class="text-primary-tint20" />
  {/if}

  {#if $featureFlagsStore.debit_period_next_due_date}
    <MCreditDueAmountSection
      titleLeft={$t('mcredit.debit_period')}
      valueLeft={$mCreditStore.creditSettle.creditTpContract}
      titleRight={$t('mcredit.next_due_date')}
      valueRight={getQueryDateFormat($mCreditStore.mCreditSummary.nextDueDate)}
    />
  {/if}
</section>
