import alpha_ro from '../../assets/images/banks/alpha_ro.png';
import bcr_ro from '../../assets/images/banks/bcr_ro.png';
import brd_ro from '../../assets/images/banks/brd_ro.png';
import bt_ro from '../../assets/images/banks/bt_ro.png';
import cec_ro from '../../assets/images/banks/cec_ro.png';
import crediteurope_ro from '../../assets/images/banks/crediteurope_ro.png';
import exim_ro from '../../assets/images/banks/exim_ro.png';
import first_ro from '../../assets/images/banks/first_ro.png';
import garanti_ro from '../../assets/images/banks/garanti_ro.png';
import ing_ro from '../../assets/images/banks/ing_ro.png';
import intesa_ro from '../../assets/images/banks/intesa_ro.png';
import libra_ro from '../../assets/images/banks/libra_ro.png';
import otp_ro from '../../assets/images/banks/otp_ro.png';
import patria_ro from '../../assets/images/banks/patria_ro.png';
import revolut_ro from '../../assets/images/banks/revolut_ro.png';
import rzb_ro from '../../assets/images/banks/rzb_ro.png';
// import techventures_ro from '../../assets/images/banks/techventures_ro.png'; // "soon" to be added
import unicredit_ro from '../../assets/images/banks/unicredit_ro.png';
import vista_ro from '../../assets/images/banks/vista_ro.png';
import { MPAY_ENV } from '../../env';
import { BankInterface, BankName } from '../constants';

const bankLogos = {
  alpha_ro,
  bcr_ro,
  brd_ro,
  bt_ro,
  cec_ro,
  crediteurope_ro,
  exim_ro,
  first_ro,
  garanti_ro,
  ing_ro,
  intesa_ro,
  libra_ro,
  otp_ro,
  patria_ro,
  revolut_ro,
  rzb_ro,
  // techventures_ro, // "soon" to be added
  unicredit_ro,
  vista_ro,
};

export type BankRecord = {
  bankInterfaces: BankInterface[];
  logo: string;
  name: BankName;
};

const allBanks: BankRecord[] = [
  {
    bankInterfaces: [BankInterface.RETAIL],
    logo: bankLogos[BankName.ALPHA_RO],
    name: BankName.ALPHA_RO,
  },
  {
    bankInterfaces: [BankInterface.RETAIL],
    logo: bankLogos[BankName.BCR_RO],
    name: BankName.BCR_RO,
  },
  {
    bankInterfaces: [BankInterface.RETAIL],
    logo: bankLogos[BankName.BT_RO],
    name: BankName.BT_RO,
  },
  {
    bankInterfaces: [BankInterface.RETAIL, BankInterface.CORPORATE],
    logo: bankLogos[BankName.BRD_RO],
    name: BankName.BRD_RO,
  },
  {
    bankInterfaces: [BankInterface.RETAIL],
    logo: bankLogos[BankName.CEC_RO],
    name: BankName.CEC_RO,
  },
  {
    bankInterfaces: [BankInterface.RETAIL, BankInterface.CORPORATE],
    logo: bankLogos[BankName.CREDITEUROPE_RO],
    name: BankName.CREDITEUROPE_RO,
  },
  {
    bankInterfaces: [BankInterface.RETAIL],
    logo: bankLogos[BankName.EXIM_RO],
    name: BankName.EXIM_RO,
  },
  {
    bankInterfaces: [BankInterface.RETAIL],
    logo: bankLogos[BankName.FIRST_RO],
    name: BankName.FIRST_RO,
  },
  {
    bankInterfaces: [BankInterface.RETAIL],
    logo: bankLogos[BankName.GARANTI_RO],
    name: BankName.GARANTI_RO,
  },
  {
    bankInterfaces: [BankInterface.RETAIL, BankInterface.CORPORATE],
    logo: bankLogos[BankName.ING_RO],
    name: BankName.ING_RO,
  },
  {
    bankInterfaces: [BankInterface.RETAIL, BankInterface.CORPORATE],
    logo: bankLogos[BankName.INTESA_RO],
    name: BankName.INTESA_RO,
  },
  {
    bankInterfaces: [BankInterface.RETAIL],
    logo: bankLogos[BankName.LIBRA_RO],
    name: BankName.LIBRA_RO,
  },
  {
    bankInterfaces: [BankInterface.RETAIL],
    logo: bankLogos[BankName.OTP_RO],
    name: BankName.OTP_RO,
  },
  {
    bankInterfaces: [BankInterface.RETAIL],
    logo: bankLogos[BankName.PATRIA_RO],
    name: BankName.PATRIA_RO,
  },
  {
    bankInterfaces: [BankInterface.RETAIL, BankInterface.CORPORATE],
    logo: bankLogos[BankName.RZB_RO],
    name: BankName.RZB_RO,
  },
  {
    bankInterfaces: [BankInterface.RETAIL],
    logo: bankLogos[BankName.REVOLUT_RO],
    name: BankName.REVOLUT_RO,
  },
  // {
  //   bankInterfaces: [BankInterface.RETAIL],
  //   logo: bankLogos[BankName.TECHVENTURES_RO],
  //   name: BankName.TECHVENTURES_RO,
  // },
  {
    bankInterfaces: [BankInterface.RETAIL],
    logo: bankLogos[BankName.UNICREDIT_RO],
    name: BankName.UNICREDIT_RO,
  },
  {
    bankInterfaces: [BankInterface.RETAIL],
    logo: bankLogos[BankName.VISTA_RO],
    name: BankName.VISTA_RO,
  },
];

const selectedBanks: BankRecord[] = [
  {
    bankInterfaces: [BankInterface.RETAIL],
    logo: bankLogos[BankName.BT_RO],
    name: BankName.BT_RO,
  },
  {
    bankInterfaces: [BankInterface.RETAIL, BankInterface.CORPORATE],
    logo: bankLogos[BankName.ING_RO],
    name: BankName.ING_RO,
  },
  {
    bankInterfaces: [BankInterface.RETAIL],
    logo: bankLogos[BankName.REVOLUT_RO],
    name: BankName.REVOLUT_RO,
  },
];

export const banks = MPAY_ENV === 'production' ? selectedBanks : allBanks;

export const isBankValid = (bank: string) =>
  banks.some((bankRecord) => bankRecord.name === bank);

export const getBank = (bank: string) =>
  banks.find((bankRecord) => bankRecord.name === bank);
