<script lang="ts">
  import { onMount } from 'svelte';
  import { t } from 'svelte-i18n';
  import { Route, Router } from 'svelte-routing';
  import AuthenticatedRoute from './lib/components/AuthenticatedRoute.svelte';
  import EmptyScreen from './lib/components/EmptyScreen.svelte';
  import PlatformInfo from './lib/components/PlatformInfo.svelte';
  import RepaymentStatusCatcher from './lib/components/RepaymentStatusCatcher.svelte';
  import { hideLoader } from './lib/JSBridge/loaderController';
  import Authentication from './lib/routes/Authenitcation.svelte';
  import AuthenitcationFailed from './lib/routes/AuthenitcationFailed.svelte';
  import BankSelection from './lib/routes/BankSelection.svelte';
  import BankSelectionType from './lib/routes/BankSelectionType.svelte';
  import Home from './lib/routes/Home.svelte';
  import Invoice from './lib/routes/Invoice.svelte';
  import InvoiceDetails from './lib/routes/InvoiceDetails.svelte';
  import Repayment from './lib/routes/Repayment.svelte';
  import RepaymentDetails from './lib/routes/RepaymentDetails.svelte';
  import { isAndroid } from './lib/utils/detectPlatform';
  import { routes } from './routes';

  export let url = '';

  onMount(() => {
    import('pdfjs-dist/build/pdf.worker.mjs');

    if (!isAndroid()) {
      document.body.classList.add('ios');
    }

    // hiding the loader triggered in main.ts file
    hideLoader();
  });
</script>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="true" />
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap"
    rel="stylesheet"
  />
</svelte:head>

{#if import.meta.env.MODE !== 'test'}
  <!-- Hide the app for screens > 767px -->
  <div
    class="fixed inset-0 z-[100] hidden h-screen w-screen bg-white min-[768px]:block"
  >
    <EmptyScreen label={$t('error.device')} />

    <PlatformInfo />
  </div>
{/if}

<Router {url}>
  <AuthenticatedRoute path={routes.home.path} component={Home} />
  <AuthenticatedRoute
    path={routes.mcredit.path}
    component={Home}
    beforeAuthWrapper={RepaymentStatusCatcher}
  />
  <AuthenticatedRoute path={routes.repaymentStatus.path} let:params>
    <Home repaymentStatus={params.repaymentStatus} />
  </AuthenticatedRoute>
  <AuthenticatedRoute path={routes.invoice.path} let:params>
    <Invoice id={params.id} />
  </AuthenticatedRoute>
  <AuthenticatedRoute path={routes.invoiceDetails.path} let:params>
    <InvoiceDetails id={params.id} />
  </AuthenticatedRoute>
  <AuthenticatedRoute path={routes.repayment.path} component={Repayment} />
  <AuthenticatedRoute path={routes.repaymentDetails.path} let:params>
    <RepaymentDetails id={params.id} />
  </AuthenticatedRoute>
  <AuthenticatedRoute
    path={routes.repaymentBankSelection.path}
    component={BankSelection}
  />
  <AuthenticatedRoute path={routes.repaymentBankSelectionType.path} let:params>
    <BankSelectionType bank={params.bank} />
  </AuthenticatedRoute>
  <Route
    path={routes.authenticationFailed.path}
    component={AuthenitcationFailed}
  />
  <Route path={routes.authenticate.path} let:params>
    <Authentication redirectUrl={params['*']} />
  </Route>
</Router>
