<script lang="ts">
  import { t } from 'svelte-i18n';
  import EmptyScreen from '../components/EmptyScreen.svelte';
  import PlatformInfo from '../components/PlatformInfo.svelte';
  import Layout from '../layouts/main.svelte';
</script>

<Layout>
  <div data-cy="authentication-failed-screen" class="mt-6">
    <EmptyScreen label={$t('error.auth_failure')} />

    <PlatformInfo />
  </div>
</Layout>
