import type { EnvTarget } from '../../types/env.types';

export const idamSupporttedCountryCodes = [
  'BE',
  'DE',
  'ES',
  'FR',
  'IT',
  'MD',
  'NL',
  'PT',
  'RO',
  'RS',
] as const;

type COUNTRY_IDAM_AUTH = Record<IdamSupportedCountryCode, IDAM_AUTH>;
export type IDAM_AUTH = {
  AUTH_HOST: string;
  AUTH_PATH: string;
  CLIENT_ID: string;
  REALM_ID: string;
  RESPONSE_TYPE: string;
  SCOPE: string;
  USER_TYPE: string;
};
export type IdamSupportedCountryCode =
  (typeof idamSupporttedCountryCodes)[number];

const COMMON_IDAM_AUTH_PARAMS: Omit<IDAM_AUTH, 'AUTH_HOST' | 'REALM_ID'> = {
  AUTH_PATH: '/authorize/api/oauth2/authorize',
  CLIENT_ID: 'MFS_MPAY_CUST',
  RESPONSE_TYPE: 'token',
  SCOPE: 'openid',
  USER_TYPE: 'CUST',
};

const ppIdamAuth: COUNTRY_IDAM_AUTH = {
  BE: {
    AUTH_HOST: 'idam-pp.metro.be',
    REALM_ID: 'SSO_CUST_BE',
    ...COMMON_IDAM_AUTH_PARAMS,
  },
  DE: {
    AUTH_HOST: 'idam-pp.metro.de',
    REALM_ID: 'SSO_CUST_DE',
    ...COMMON_IDAM_AUTH_PARAMS,
  },
  ES: {
    AUTH_HOST: 'idam-pp.makro.es',
    REALM_ID: 'SSO_CUST_ES',
    ...COMMON_IDAM_AUTH_PARAMS,
  },
  FR: {
    AUTH_HOST: 'idam-pp.metro.fr',
    REALM_ID: 'SHOP_FR',
    ...COMMON_IDAM_AUTH_PARAMS,
  },
  IT: {
    AUTH_HOST: 'idam-pp.metro.it',
    REALM_ID: 'SSO_CUST_IT',
    ...COMMON_IDAM_AUTH_PARAMS,
  },
  MD: {
    AUTH_HOST: 'idam-pp.metro.md',
    REALM_ID: 'SSO_CUST_MD',
    ...COMMON_IDAM_AUTH_PARAMS,
  },
  NL: {
    AUTH_HOST: 'idam-pp.makro.nl',
    REALM_ID: 'SSO_CUST_NL',
    ...COMMON_IDAM_AUTH_PARAMS,
  },
  PT: {
    AUTH_HOST: 'idam-pp.makro.pt',
    REALM_ID: 'SSO_CUST_PT',
    ...COMMON_IDAM_AUTH_PARAMS,
  },
  RO: {
    AUTH_HOST: 'idam-pp.metro.ro',
    REALM_ID: 'SSO_CUST_RO',
    ...COMMON_IDAM_AUTH_PARAMS,
  },
  RS: {
    AUTH_HOST: 'idam-pp.metro.rs',
    REALM_ID: 'SSO_CUST_RS',
    ...COMMON_IDAM_AUTH_PARAMS,
  },
};
const prodIdamAuth: COUNTRY_IDAM_AUTH = {
  BE: {
    AUTH_HOST: 'idam.metro.be',
    REALM_ID: 'SSO_CUST_BE',
    ...COMMON_IDAM_AUTH_PARAMS,
  },
  DE: {
    AUTH_HOST: 'idam.metro.de',
    REALM_ID: 'SSO_CUST_DE',
    ...COMMON_IDAM_AUTH_PARAMS,
  },
  ES: {
    AUTH_HOST: 'idam.makro.es',
    REALM_ID: 'SSO_CUST_ES',
    ...COMMON_IDAM_AUTH_PARAMS,
  },
  FR: {
    AUTH_HOST: 'idam.metro.fr',
    REALM_ID: 'SHOP_FR',
    ...COMMON_IDAM_AUTH_PARAMS,
  },
  IT: {
    AUTH_HOST: 'idam.metro.it',
    REALM_ID: 'SSO_CUST_IT',
    ...COMMON_IDAM_AUTH_PARAMS,
  },
  MD: {
    AUTH_HOST: 'idam.metro.md',
    REALM_ID: 'SSO_CUST_MD',
    ...COMMON_IDAM_AUTH_PARAMS,
  },
  NL: {
    AUTH_HOST: 'idam.makro.nl',
    REALM_ID: 'SSO_CUST_NL',
    ...COMMON_IDAM_AUTH_PARAMS,
  },
  PT: {
    AUTH_HOST: 'idam.makro.pt',
    REALM_ID: 'SSO_CUST_PT',
    ...COMMON_IDAM_AUTH_PARAMS,
  },
  RO: {
    AUTH_HOST: 'idam.metro.ro',
    REALM_ID: 'SSO_CUST_RO',
    ...COMMON_IDAM_AUTH_PARAMS,
  },
  RS: {
    AUTH_HOST: 'idam.metro.rs',
    REALM_ID: 'SSO_CUST_RS',
    ...COMMON_IDAM_AUTH_PARAMS,
  },
};

export const idamAuthMappings: Record<EnvTarget, COUNTRY_IDAM_AUTH> = {
  local: ppIdamAuth,
  pp: ppIdamAuth,
  prod: prodIdamAuth,
  test: ppIdamAuth,
};
