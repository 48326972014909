import posthog, { type CaptureResult } from 'posthog-js';
import { MPAY_ENV, MPAY_POSTHOG_API_HOST, MPAY_POSTHOG_TOKEN } from '../env';
import { getStrippedUrl } from '../lib/utils/helpers';
import { getPlatformInfo } from '../lib/utils/platformInfo';

let isInitialized = false;

export class PostHogPluginMissingError extends Error {
  constructor() {
    super('PostHog plugin is not initialized');
    this.name = 'PostHogPluginMissingError';
  }
}

export async function initializePostHog() {
  if (isInitialized) return;
  isInitialized = true;

  posthog.init(MPAY_POSTHOG_TOKEN, {
    api_host: MPAY_POSTHOG_API_HOST,
    autocapture: false,
    capture_pageview: false,
    debug: MPAY_ENV === 'local' || MPAY_ENV === 'pre-production',
    disable_cookie: true,
    disable_session_recording: true,
    persistence: 'memory',
    before_send: (event: CaptureResult | null): CaptureResult | null => {
      if (!event) return null;

      if (event.properties) {
        const urlProperties = [
          '$current_url',
          '$initial_referrer',
          '$referrer',
          'url',
        ];
        for (const prop of urlProperties) {
          if (prop in event.properties) {
            event.properties[prop] = getStrippedUrl(event.properties[prop]);
          }
        }
      }

      return event;
    },
  });

  const platformInfo = await getPlatformInfo();

  posthog.register({
    env: MPAY_ENV,
    operating_system: platformInfo.operatingSystem,
    platform: platformInfo.platform,
    web_build: platformInfo.webBuild,
    web_version: platformInfo.webVersion,
    native_build_number: platformInfo.nativeBuildNumber,
    native_flavor: platformInfo.nativeFlavor,
    native_version: platformInfo.nativeVersion,
  });

  posthog.setPersonPropertiesForFlags({
    $initial_referring_domain: MPAY_ENV,
  });
}

export { posthog };
