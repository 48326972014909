<script lang="ts">
  import { t } from 'svelte-i18n';
  import { Link } from 'svelte-routing';
  import type { Repayment } from '../../types/mpay.types';
  import Icon from './Icon.svelte';
  import ListInvoiceAmount from './ListInvoiceAmount.svelte';

  export let repayment: Repayment;
  export let href: string = '';
  export let onClick: () => void;

  $: isCompleted = repayment.status === 'completed';
</script>

<li
  class={`border-b border-primary-tint90 px-5 last:border-0 ${isCompleted ? 'text-primary-base' : 'text-semantic-disabled'}`}
  data-cy="repayment-list-item"
>
  <Link to={href} on:click={onClick} data-cy="repayment-list-item-link">
    <div
      class="grid grid-cols-[40px_minmax(0,1fr)_80px] items-center gap-3 pb-[15px] pt-[17px]"
    >
      <div
        class={`flex h-[40px] w-[40px] items-center justify-center rounded-lg p-2 ${isCompleted ? 'bg-semantic-inverted text-semantic-neutral shadow-small' : 'bg-primary-tint90 text-semantic-disabled'}`}
        data-cy="repayment-list-item-icon"
      >
        <Icon iconName={'Logout'} />
      </div>

      <div class="w-max-1/2 flex w-full flex-col">
        <div class="flex items-center">
          <time
            datetime={repayment.dateToDisplay}
            class="mr-2 text-android-title-medium ios:text-ios-headline ios:font-semibold"
            data-cy="repayment-list-item-timestamp"
          >
            {repayment.dateToDisplay}
          </time>
        </div>

        <div class="flex">
          <p
            class="mr-1 truncate text-android-body-small ios:text-ios-footnote"
            data-cy="repayment-list-item-label"
          >
            {$t('payment_type.a2a_repayment')}
          </p>
        </div>
      </div>

      <div
        class="flex flex-col items-end justify-end"
        data-cy="repayment-list-item-amount"
      >
        <ListInvoiceAmount
          isMain
          amount={-repayment.amount}
          type={isCompleted ? 'voucher' : 'pending'}
        />

        {#if !isCompleted}
          <span
            class="flex gap-1 truncate text-android-body-small text-primary-tint40 ios:text-ios-footnote"
            class:text-red-base={repayment.status === 'failed'}
          >
            {$t(`repayment_status.${repayment.status}`).toLowerCase()}
          </span>
        {/if}
      </div>
    </div>
  </Link>
</li>
