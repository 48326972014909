<script lang="ts">
  import { fly } from 'svelte/transition';

  const windowHeight = window.innerHeight;
</script>

<div
  class="absolute bottom-0 left-0 right-0 top-0 z-50"
  in:fly={{ y: windowHeight, duration: 300, opacity: 1 }}
  out:fly={{ y: windowHeight, duration: 300, opacity: 1 }}
>
  <slot />
</div>
