import { Context } from './lib/utils/analytics/keys';

export const routes = {
  home: {
    name: Context.Home,
    path: '/',
  },
  authenticate: {
    name: Context.Authentication,
    path: '/authenticate/*',
  },
  authenticationFailed: {
    name: Context.Authentication,
    path: '/authenticate/failed',
  },
  invoice: {
    name: Context.Invoice,
    path: '/invoice/:id',
  },
  invoiceDetails: {
    name: Context.InvoiceDetails,
    path: '/invoice-details/:id',
  },
  mcredit: {
    name: Context.Mcredit,
    path: '/mcredit/*',
  },
  repayment: {
    name: Context.Repayment,
    path: '/repayment',
  },
  repaymentBankSelection: {
    name: Context.BankSelection,
    path: '/repayment/bank-selection',
  },
  repaymentBankSelectionType: {
    name: Context.BankSelectionType,
    path: '/repayment/bank-selection/:bank',
  },
  repaymentDetails: {
    name: Context.RepaymentDetails,
    path: '/repayment-details/:id',
  },
  repaymentStatus: {
    name: Context.Mcredit,
    path: '/mcredit/repayment-status/:repaymentStatus',
  },
} as const;
