import { get, writable } from 'svelte/store';
import { MPAY_API_BASE_URI } from '../env';
import { TARGET } from '../env';
import { BankInterface, BankName, MPayApi } from '../lib/constants';
import { sendRequest } from '../lib/utils/fetch';

type Settings = {
  accountDetails: {
    cardHolderId: string;
    country: string;
    mcreditAccess: boolean;
    normalizedCustomerId: string;
  };
  activeTab: number;
  credentials: {
    accountId: string;
    token: string;
  };
  isGraphExpanded: boolean;
  selectedBank: null | {
    bankInterface: BankInterface | null;
    name: BankName;
  };
};

const initialState: Settings = {
  accountDetails: {
    cardHolderId: '',
    country: '',
    mcreditAccess: false,
    normalizedCustomerId: '',
  },
  activeTab: 1,
  credentials: {
    accountId: '',
    token: '',
  },
  isGraphExpanded: false,
  selectedBank: null,
};

const createSettingsStore = () => {
  const { subscribe, update } = writable(initialState);

  return {
    setAccountDetails: (value: Settings['accountDetails']) => {
      if (!value.mcreditAccess) {
        update((state) => ({ ...state, activeTab: 1 }));
      }
      update((state) => ({ ...state, accountDetails: value }));
    },
    setActiveTab: (value: number) =>
      update((state) => ({ ...state, activeTab: value })),
    setCredentials: (value: Settings['credentials']) => {
      update((state) => ({ ...state, credentials: value }));
    },
    setIsGraphExpanded: (value: boolean) =>
      update((state) => ({ ...state, isGraphExpanded: value })),
    setRepaymentStatus: (value: string) =>
      update((state) => ({ ...state, repaymentStatus: value })),
    setSelectedBankAccount: (value: {
      bankInterface: BankInterface;
      selectedBank: BankName;
    }) =>
      update((state) => ({
        ...state,
        selectedBank: {
          bankInterface: value.bankInterface,
          name: value.selectedBank,
        },
      })),
    subscribe,
  };
};

export const settingsStore = createSettingsStore();

if (['local', 'test'].includes(TARGET)) {
  Object.defineProperty(window, 'settingsStore', {
    get() {
      return {
        ...get(settingsStore),
        ...settingsStore,
      };
    },
  });
}

export const fetchAccountDetails = async () => {
  const settings = get(settingsStore);
  const url = `${MPAY_API_BASE_URI}/${MPayApi.PERSON_DETAILS}?accountId=${settings.credentials.accountId}`;
  const response = await sendRequest<Settings['accountDetails']>(url);

  settingsStore.setAccountDetails(response);
};
