<script lang="ts">
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import { routeBuilders } from '../utils/routeBuilders';
  let showWrapped = false;

  const validRepaymentStatuses = ['completed', 'failed', 'revoked'];

  onMount(() => {
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const repaymentStatus = searchParams.get('status');
    searchParams.delete('status');

    if (repaymentStatus && validRepaymentStatuses.includes(repaymentStatus)) {
      const restParams = url.searchParams
        .entries()
        .reduce((params, [key, value]) => {
          return { ...params, [key]: value };
        }, {});
      const redirectRoute = routeBuilders.repaymentStatus({
        repaymentStatus,
        ...restParams,
      });

      navigate(redirectRoute, { replace: true });
    } else {
      showWrapped = true;
    }
  });
</script>

{#if showWrapped}
  <slot />
{/if}
