import { get } from 'svelte/store';
import type { IdamSupportedCountryCode } from './idamAuthMappings';
import { posthog } from '../../plugins/posthog';
import { settingsStore } from '../../store/settingsStore';
import { MethodType } from '../constants';
import { isValidToken } from './auth';
import { getIdamUrl } from './getIdamUrl';
import { getStoredCountryCode, removeStoredToken } from './localStorage';

export class ResetApplicationException extends Error {
  constructor() {
    super('Resetting the App!');
    this.name = 'ResetApplicationException';
  }
}

export type Request = {
  data?: BodyInit | null | undefined;
  method?: MethodType | string;
  url: string;
};

function resetApplication(accountId: string) {
  const countryCode = getStoredCountryCode() as IdamSupportedCountryCode;
  if (!countryCode) {
    throw new Error('Application should but can not be reseted!');
  }

  removeStoredToken();
  posthog.capture('reset_application');
  window.location.href = getIdamUrl(
    countryCode,
    accountId,
    window.location.pathname
  );
  throw new ResetApplicationException();
}

/**
 * Sends fetch request to the given endpoint with the request details.
 */
export async function sendRequest<T = unknown>(
  input: RequestInfo | URL,
  init?: RequestInit
): Promise<T> {
  try {
    const settings = get(settingsStore);
    const { accountId, token } = settings.credentials;

    const headers = new Headers({
      'Content-Type': 'application/json',
      ...init?.headers,
    });

    if (token && isValidToken(token)) {
      headers.append('Authorization', `Bearer ${token}`);
    } else if (token && accountId) {
      console.warn('Token is invalid!');
      resetApplication(accountId);
    }

    const response = await fetch(input, {
      ...init,
      headers,
    });

    if (!response.ok) {
      // TODO: remove the test mode check once we have proper e2e tests
      if (
        !!accountId &&
        response.status === 401 &&
        import.meta.env.MODE !== 'test'
      ) {
        console.warn('Token is invalid');
        resetApplication(accountId);
      }

      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // handle POST 201 response case with no body
    if (response.status === 201) {
      return {} as T;
    }

    return (await response.json()) as T;
  } catch (error) {
    if (error instanceof TypeError) {
      console.warn('Network error:', error);
    } else if ((error as Error).name === 'AbortError') {
      console.warn('Request timeout:', error);
    } else {
      console.error('HTTP error:', error);
    }

    throw error; // re-throwing the error to be handled by the caller
  }
}
