<script lang="ts">
  import { featureFlagsStore } from '../../store/featureFlagsStore';
  import { settingsStore } from '../../store/settingsStore';

  $: firstTabClasses = (() => {
    const baseClasses = ['fixed z-20 mt-auto h-36 w-screen'];

    return [
      ...baseClasses,
      $settingsStore.accountDetails.mcreditAccess
        ? 'top-topAndNavBars'
        : 'top-topBar',
    ];
  })();

  $: secondTabClasses = (() => {
    // Height map based on elements visibility
    const heightMap: Record<string, string> = {
      'true-true-true': 'h-[540px]', // All visible
      'true-true-false': 'h-[465px]', // Due/Overdue & Repayment
      'false-true-true': 'h-[465px]', // Repayment & Debit Period/Next Due Date
      'true-false-true': 'h-[480px]', // Due/Overdue & Debit Period/Next Due Date
      'true-false-false': 'h-[395px]', // Only Due/Overdue
      'false-false-true': 'h-[395px]', // Only Debit Period
    };

    const visibilityKey = [
      $featureFlagsStore.due_overdue_amount,
      $featureFlagsStore.repayment,
      $featureFlagsStore.debit_period_next_due_date
    ].join('-');

    return [['mt-navBar'], heightMap[visibilityKey] || 'h-[400px]'];
  })();

  $: activeTabClasses = (() => {
    const paddingClass = $settingsStore.isGraphExpanded ? 'py-7' : 'py-9';

    return [
      paddingClass,
      ...($settingsStore.activeTab === 1 ? firstTabClasses : secondTabClasses),
    ];
  })();
</script>

<div
  class={[
    'bg-primary-darken20 px-6 transition-all duration-300',
    ...activeTabClasses,
  ].join(' ')}
  data-cy="bar-graph-section"
>
  <slot />
</div>
