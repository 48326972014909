import { navigate } from 'svelte-routing';
import { nativeFn, supportsNativeFn } from './callNative';

export function navigateBack(): void {
  if (
    (window.location.pathname === '/' || window.history.state === null) &&
    supportsNativeFn('closeWebView')
  ) {
    nativeFn.closeWebView();
  } else {
    window.history.back();
  }
}

function navigateTo(route: string): void {
  navigate(route, { replace: false, state: {} });
}

export function registerGlobalNavigation(): void {
  window.navigateBack = navigateBack;
  window.navigateTo = navigateTo;
}
