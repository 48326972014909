<script lang="ts">
  import { t } from 'svelte-i18n';
  import type { BuonoVoucher } from '../constants';
  import { getDisplayDate } from '../utils/formatters';
  import Dialog from './Dialog.svelte';
  import ListInvoiceAmount from './ListInvoiceAmount.svelte';
  import SelectableList from './SelectableList/SelectableList.svelte';
  import SelectableListItem from './SelectableList/SelectableListItem.svelte';

  export let showDialog = false;
  export let onSelect: (selectedInvoices: BuonoVoucher[]) => void;
  let selectedVouchers: BuonoVoucher[] = [];

  // Test data
  const currentDate = new Date();
  const twoDaysEarlier = new Date(currentDate);
  twoDaysEarlier.setDate(currentDate.getDate() - 2);
  const threeDaysLater = new Date(currentDate);
  threeDaysLater.setDate(currentDate.getDate() + 3);

  const vouchers: BuonoVoucher[] = [
    {
      id: '1',
      amount: -9674,
      date: twoDaysEarlier,
      dateToDisplay: getDisplayDate(twoDaysEarlier),
      label: 'Buonu METRO Pro',
    },
    {
      id: '2',
      amount: -5000,
      date: threeDaysLater,
      dateToDisplay: getDisplayDate(threeDaysLater),
      label: 'Buonu METRO Pro',
    },
    {
      id: '3',
      amount: -6000,
      date: threeDaysLater,
      dateToDisplay: getDisplayDate(threeDaysLater),
      label: 'Buonu METRO Pro',
    },
    {
      id: '4',
      amount: -7000,
      date: threeDaysLater,
      dateToDisplay: getDisplayDate(threeDaysLater),
      label: 'Buonu METRO Pro',
    },
    {
      id: '5',
      amount: -8000,
      date: threeDaysLater,
      dateToDisplay: getDisplayDate(threeDaysLater),
      label: 'Buonu METRO Pro',
    },
    {
      id: '6',
      amount: -9000,
      date: threeDaysLater,
      dateToDisplay: getDisplayDate(threeDaysLater),
      label: 'Buonu METRO Pro',
    },
  ];
  // Test data end

  const onAddToSelected = (voucher: BuonoVoucher) => {
    const id = selectedVouchers.findIndex(({ id }) => id == voucher.id);
    if (id === -1) {
      selectedVouchers = [...selectedVouchers, voucher];
    }
  };

  const onRemoveFromSelected = (invoice: BuonoVoucher) => {
    selectedVouchers = selectedVouchers.filter(({ id }) => id !== invoice.id);
  };
</script>

<Dialog
  bind:showDialog
  title={$t('repayment_select_invoices.buono_dialog_title')}
  description={$t('repayment_select_invoices.buono_dialog_description')}
  primaryButtonLabel="save"
  showCancelButton
  autoGapContent={false}
  cancelButtonLabel="cancel"
  shouldNavigateBack={false}
  onPrimaryClick={[() => onSelect(selectedVouchers)]}
  displayIcon={false}
>
  <div
    class="mt-6 max-h-60 overflow-y-scroll border-t border-primary-tint90 px-6"
  >
    <SelectableList>
      {#each vouchers as voucher}
        <SelectableListItem
          isSelected={selectedVouchers.some(({ id }) => id === voucher.id)}
          onChange={(selected) =>
            selected ? onAddToSelected(voucher) : onRemoveFromSelected(voucher)}
        >
          <div class="flex flex-row justify-between py-4 text-primary-base">
            <div class="flex flex-col items-center">
              <time datetime={voucher.dateToDisplay}>
                {voucher.dateToDisplay}
              </time>
              <span>
                {voucher.label}
              </span>
            </div>
            <ListInvoiceAmount type="total" isMain amount={voucher.amount} />
          </div>
        </SelectableListItem>
      {/each}
    </SelectableList>
  </div>
</Dialog>
