export const isAndroid = (): boolean => {
  const userAgent = navigator.userAgent || '';
  return (
    /android/i.test(userAgent) ||
    /Linux.*Mobile/.test(userAgent) ||
    isAndroidNative()
  );
};

export const isIos = (): boolean => {
  const userAgent = navigator.userAgent || '';
  return (
    isIosNative() ||
    /iPhone|iPad|iPod/.test(userAgent) ||
    (/Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent) &&
      navigator.maxTouchPoints > 0)
  );
};

export const isIosNative = (): boolean => {
  return window.webkit?.messageHandlers !== undefined;
};

export const isAndroidNative = (): boolean => {
  return window.MPay !== undefined;
};

export const isNative = (): boolean => {
  return isIosNative() || isAndroidNative();
};
