import { compareDesc, parseISO } from 'date-fns';
import type {
  Invoice,
  MonthlyInvoices,
  MonthlyTransactions,
  Repayment,
  Transactions,
} from '../../types/mpay.types';
import { PayloadEntryType } from '../constants';
import { getDisplayDate, getFormattedISO8601DateLocal } from './formatters';
import { getMonthYearFromDateTranslation } from './translations';

export function enrichInvoicesWithDataFormats(invoices: Invoice[]): Invoice[] {
  return invoices.map((invoice) => ({
    ...invoice,
    dateToCompare: invoice.date,
    dateToDisplay: getDisplayDate(new Date(invoice.date)),
    type: PayloadEntryType.INVOICE,
  }));
}

export function enrichRepaymentsWithDataFormats(
  payments: Repayment[]
): Repayment[] {
  return payments.map((payment) => ({
    ...payment,
    date: payment.timestamp,
    dateToCompare: getFormattedISO8601DateLocal(payment.timestamp),
    dateToDisplay: getDisplayDate(new Date(payment.timestamp), true),
    type: PayloadEntryType.REPAYMENT,
  }));
}

export function getMergedInvoices(
  oldVal: MonthlyInvoices,
  newVal: MonthlyInvoices
): MonthlyInvoices {
  if (Object.keys(oldVal).length > 0) {
    Object.keys(newVal).forEach((key) => {
      if (oldVal[key]) {
        const existingInvoices = new Set(
          oldVal[key].map((invoice) => invoice.number)
        );

        // filter out invoices with duplicate numbers
        const uniqueNewInvoices = newVal[key].filter(
          (invoice) => !existingInvoices.has(invoice.number)
        );

        oldVal[key].push(...uniqueNewInvoices);
      } else {
        oldVal[key] = newVal[key];
      }
    });
    return oldVal;
  } else {
    return newVal;
  }
}

export function getMergedTransactionsData(
  oldVal: MonthlyTransactions,
  newVal: MonthlyTransactions
): MonthlyTransactions {
  if (Object.keys(oldVal).length === 0) return newVal;

  Object.entries(newVal).forEach(([key, newItems]) => {
    if (oldVal[key]) {
      const existingKeys = new Set(
        oldVal[key].map((item) => (isInvoiceType(item) ? item.number : item.id))
      );

      oldVal[key].push(
        ...newItems.filter((item) =>
          isInvoiceType(item)
            ? !existingKeys.has(item.number)
            : !existingKeys.has(item.id)
        )
      );
    } else {
      oldVal[key] = newItems;
    }
  });

  return oldVal;
}

export function getRecordById<T extends Invoice | Repayment>(
  data: MonthlyInvoices | MonthlyTransactions,
  id: string
): null | T {
  let foundRecord: null | T = null;

  Object.keys(data).forEach((month) => {
    const currResult = data[month].find(
      (item) => (isInvoiceType(item) ? item.number : item.id) === id
    ) as null | T;

    if (currResult) {
      foundRecord = currResult;
    }
  });

  return foundRecord;
}

export function getSortedInvoicesByMonthYear(
  invoices: Invoice[]
): MonthlyInvoices {
  return invoices.reduce((acc: MonthlyInvoices, curr) => {
    const invMonthYear = getMonthYearFromDateTranslation(curr.date);

    acc[invMonthYear] = [...(acc[invMonthYear] || []), curr];

    return acc;
  }, {});
}

export function getTransactions(
  invoices: Invoice[],
  payments: Repayment[]
): Transactions {
  return [
    ...enrichInvoicesWithDataFormats(invoices),
    ...enrichRepaymentsWithDataFormats(payments),
  ].sort((a, b) =>
    compareDesc(
      typeof a.dateToCompare === 'string'
        ? parseISO(a.dateToCompare)
        : new Date(a.dateToCompare),
      typeof b.dateToCompare === 'string'
        ? parseISO(b.dateToCompare)
        : new Date(b.dateToCompare)
    )
  );
}

export function isInvoiceType(data: Invoice | Repayment): data is Invoice {
  return data.type === PayloadEntryType.INVOICE;
}

export function isRepaymentType(data: Invoice | Repayment): data is Repayment {
  return data.type === PayloadEntryType.REPAYMENT;
}
