<script lang="ts">
  import { type TopBarButton } from '../../store/TopBarStore';
  import Icon from '../components/Icon.svelte';

  export let showBackButton: boolean;
  export let backButtonLabel: string | undefined = undefined;
  export let onClickBackButton: () => void;
  export let title: string;
  export let button: TopBarButton | undefined = undefined;
</script>

<nav
  data-cy="top-bar"
  class="fixed z-50 grid h-topBar w-screen grid-cols-4 gap-4 bg-primary-base px-4 text-white"
>
  <div class="col-span-1 flex items-center">
    <button
      data-cy="back-btn"
      on:click={onClickBackButton}
      class:hidden={!showBackButton}
    >
      {#if backButtonLabel}
        {backButtonLabel}
      {:else}
        <Icon iconName="ChevronLeft" />
      {/if}
    </button>
  </div>

  <div
    class="col-span-2 flex items-center justify-center text-android-title-large ios:text-[1.0625rem] ios:font-semibold"
  >
    {title}
  </div>

  <div class="col-span-1 flex items-center justify-end">
    {#if button}
      <button
        data-cy="top-bar-button"
        class="text-android-title-small transition-opacity ios:text-ios-body"
        on:click={button.onClick}
      >
        {button.label}
      </button>
    {/if}
  </div>
</nav>
