import type { MetroStore } from '../types/mpay.types';

export enum BankInterface {
  CORPORATE = 'corporate',
  RETAIL = 'retail',
}

export enum BankName {
  ALPHA_RO = 'alpha_ro',
  BCR_RO = 'bcr_ro',
  BRD_RO = 'brd_ro',
  BT_RO = 'bt_ro',
  CEC_RO = 'cec_ro',
  CREDITEUROPE_RO = 'crediteurope_ro',
  EXIM_RO = 'exim_ro',
  FIRST_RO = 'first_ro',
  GARANTI_RO = 'garanti_ro',
  ING_RO = 'ing_ro',
  INTESA_RO = 'intesa_ro',
  LIBRA_RO = 'libra_ro',
  OTP_RO = 'otp_ro',
  PATRIA_RO = 'patria_ro',
  REVOLUT_RO = 'revolut_ro',
  RZB_RO = 'rzb_ro',
  // TECHVENTURES_RO, // "soon" to be added
  UNICREDIT_RO = 'unicredit_ro',
  VISTA_RO = 'vista_ro',
}

export enum CreditSettleFrequency {
  EMPTY = '',
  FIX15 = '15 FIX',
  FIX30 = '30 FIX',
  MEDIU14 = '14 MEDIU',
  MEDIU20 = '20 MEDIU',
  MEDIU21 = '21 MEDIU',
  MEDIU26 = '26 MEDIU',
  MEDIU30 = '30 MEDIU',
  MEDIU45 = '45 MEDIU',
}

export enum CustomerInvoiceType {
  COD = 'COD',
  EMPTIES = 'EMPTIES',
  INVOICE = 'INVOICE',
  POD = 'PAYMENT_RECEIPT',
  RETURN = 'RETURN',
  VOIDING_INVOICE = 'VOIDING_INVOICE',
}

export enum DateRange {
  LAST_MONTH = 'last_month',
  LAST_YEAR = 'last_year',
  THIS_AND_LAST_YEAR = 'this_and_last_year',
  THIS_MONTH = 'this_month',
  THIS_QUARTER = 'this_quarter',
  THIS_YEAR = 'this_year',
}

export enum InvoiceType {
  LEGA = 'LEGA',
  PRIV = 'PRIV',
}

export enum MethodType {
  GET = 'GET',
  POST = 'POST',
}

export enum MPayApi {
  CREDIT_LIMITS = 'credit-limits',
  CREDIT_SETTLE = 'mcredit/credit-settle',
  INVOICES = 'invoices',
  METRO_STORES = 'stores',
  PAYMENTS = 'payments',
  PDF_PROXY = 'invoice',
  PERSON_DETAILS = 'person-details',
  PROFILES = 'profiles',
}

export enum PayloadEntryType {
  INVOICE = 'invoice',
  REPAYMENT = 'repayment',
}

export enum PaymentStatus {
  DUE = 'due',
  OVERDUE = 'overdue',
  PARTIALLY_REPAID = 'partially-repaid',
  PENDING = 'pending',
  REPAID = 'repaid',
}
// this is the error we get from Cloud Armour when there are too many requests
// as it does not return an error code, the request just fails, here's what it returns
export const ERR_429 = 'TypeError: Failed to fetch';

export const CARD_CODE = '300';
export const MCREDIT_CODE = '600';
export const ACCOUNT_ID_ITEM = 'accountId';
export const TOKEN_ITEM = 'token';
export const COUNTRY_CODE_ITEM = 'countryCode';

// Payment codes per their occurence order (from most common to )
export const paymentCodes = {
  1: '1',
  200: '200',
  205: '205',
  206: '206',
  208: '208',
  209: '209',
  210: '210',
  250: '250',
  300: '300',
  301: '301',
  302: '302',
  303: '303',
  304: '304',
  306: '306',
  308: '308',
  320: '320',
  321: '321',
  350: '350',
  381: '381',
  382: '382',
  400: '400',
  401: '401',
  402: '402',
  403: '403',
  404: '404',
  406: '406',
  407: '407',
  408: '408',
  409: '409',
  410: '410',
  411: '411',
  412: '412',
  413: '413',
  414: '414',
  415: '415',
  419: '419',
  420: '420',
  421: '421',
  423: '423',
  424: '424',
  425: '425',
  442: '442',
  443: '443',
  444: '444',
  445: '445',
  450: '450',
  451: '451',
  452: '452',
  453: '453',
  474: '474',
  475: '475',
  476: '476',
  477: '477',
  478: '478',
  480: '480',
  481: '481',
  482: '482',
  500: '500',
  501: '501',
  502: '502',
  503: '503',
  505: '505',
  509: '509',
  511: '511',
  600: '600',
  602: '602',
  603: '603',
  604: '604',
  610: '610',
  611: '611',
  615: '615',
  616: '616',
  619: '619',
  620: '620',
  650: '650',
  651: '651',
  652: '652',
  653: '653',
  655: '655',
  656: '656',
  657: '657',
  658: '658',
  699: '699',
  700: '700',
  800: '800',
  801: '801',
  802: '802',
  803: '803',
  804: '804',
  805: '805',
  810: '810',
  811: '811',
  812: '812',
  814: '814',
  819: '819',
  900: '900',
  901: '901',
  902: '902',
  903: '903',
  904: '904',
  905: '905',
  906: '906',
  907: '907',
  908: '908',
  910: '910',
  920: '920',
  921: '921',
  922: '922',
  923: '923',
  926: '926',
  927: '927',
  928: '928',
  929: '929',
  930: '930',
  939: '939',
  940: '940',
  950: '950',
  951: '951',
  998: '998',
  1000: '1000',
  1100: '1100',
  1101: '1101',
  1102: '1102',
  1103: '1103',
  1200: '1200',
  1201: '1201',
  1300: '1300',
  1500: '1500',
  1600: '1600',
  1700: '1700',
  1801: '1801',
  1802: '1802',
  1803: '1803',
  1804: '1804',
  1805: '1805',
  1806: '1806',
  1809: '1809',
  1901: '1901',
  1903: '1903',
  2000: '2000',
  2001: '2001',
  2002: '2002',
  2004: '2004',
  2005: '2005',
  9990: '9990',
  9991: '9991',
  10000: '10000',
  10001: '10001',
  10002: '10002',
} as const;

export type PaymentCode = keyof typeof paymentCodes;

export const metroStores: MetroStore[] = [
  {
    id: 34,
    name: 'Arad',
  },
  {
    id: 16,
    name: 'Bacău',
  },
  {
    id: 25,
    name: 'Baia Mare',
  },
  {
    id: 13,
    name: 'Brașov 1',
  },
  {
    id: 30,
    name: 'Brașov 2',
  },
  {
    id: 32,
    name: 'Băneasa',
  },
  {
    id: 27,
    name: 'Berceni',
  },
  {
    id: 11,
    name: 'Militari',
  },
  {
    id: 36,
    name: 'Pallady',
  },
  {
    id: 18,
    name: 'Voluntari',
  },
  {
    id: 40,
    name: 'Buzău',
  },
  {
    id: 15,
    name: 'Cluj',
  },
  {
    id: 14,
    name: 'Constanța 1',
  },
  {
    id: 31,
    name: 'Constanța 2',
  },
  {
    id: 17,
    name: 'Craiova',
  },
  {
    id: 35,
    name: 'Deva',
  },
  {
    id: 24,
    name: 'Galați',
  },
  {
    id: 20,
    name: 'Iași',
  },
  {
    id: 19,
    name: 'Oradea',
  },
  {
    id: 54,
    name: 'Piatra Neamț Punct',
  },
  {
    id: 28,
    name: 'Pitești',
  },
  {
    id: 22,
    name: 'Ploiești',
  },
  {
    id: 53,
    name: 'Satu Mare Punct',
  },
  {
    id: 21,
    name: 'Sibiu',
  },
  {
    id: 26,
    name: 'Suceava',
  },
  {
    id: 56,
    name: 'Târgoviște Punct',
  },
  {
    id: 23,
    name: 'Târgu Mureș',
  },
  {
    id: 12,
    name: 'Timișoara 1',
  },
  {
    id: 29,
    name: 'Timișoara 2',
  },
  {
    id: 58,
    name: 'Turda Punct',
  },
];

// Replace wich actual implementation

export type BuonoVoucher = {
  amount: number;
  date: Date;
  dateToDisplay: string;
  id: string;
  label: string;
};

export type DummyInvoice = {
  amount: number;
  currency: string;
  dateToDisplay: string;
  id: string;
  invoiceDate: Date;
  overdue: boolean;
};
