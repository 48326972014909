<script lang="ts">
  export let leftLabel: string;
  export let rightLabel: string | undefined = undefined;
</script>

<div
  class="flex justify-between border-b border-primary-tint90 py-2 text-android-title-small font-medium text-primary-tint40 ios:text-ios-caption ios:font-semibold"
>
  <p>{leftLabel}</p>
  {#if rightLabel}
    <p class="text-right">
      {rightLabel}
    </p>
  {/if}
</div>
