<script lang="ts">
  import { settingsStore } from '../../store/settingsStore';
  import { getAccountIdFromNative } from '../utils/auth';
  import { storeAccountId, storeToken } from '../utils/localStorage';
  import { routeBuilders } from '../utils/routeBuilders';

  export let redirectUrl: string;

  const setCredentials = async () => {
    const hashParams = new URLSearchParams(window.location.hash.substring(1));
    const accessToken = hashParams.get('access_token');
    const accountId =
      (await getAccountIdFromNative()) ?? hashParams.get('state');

    if (accountId && accessToken) {
      settingsStore.setCredentials({ accountId, token: accessToken });
      storeAccountId(accountId);
      storeToken(accessToken);

      console.log('setting up auth credentials');

      window.location.replace(`/${redirectUrl}`);
      return;
    }

    window.location.replace(routeBuilders.authenticationFailed());
  };

  setCredentials();
</script>
