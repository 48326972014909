<script lang="ts">
  import type { IconName } from '../../types/icons.types';
  import Icon from './Icon.svelte';

  export let label: string;
  export let icon: IconName | undefined;
  export let onClick: () => void;
  export let disabled: boolean = false;

  const buttonColor = disabled ? 'text-semantic-disabled' : 'text-blue-base';

  const handleClick = () => {
    if (disabled) return;

    onClick();
  };
</script>

<li class={`font-semibold ${buttonColor}`}>
  <button
    class="flex h-[52px] w-full items-center justify-between"
    on:click={handleClick}
  >
    {label}
    {#if icon}
      <Icon iconName={icon} size="20" />
    {/if}
  </button>
</li>
