<script lang="ts">
  import { t } from 'svelte-i18n';
  import { getCurrencyUnit } from '../utils/helpers';

  export let timePeriod: string;
  export let isFirstEntry: boolean;
  export let isMcredit: boolean = false;
</script>

<div
  class="flex justify-between border-b border-primary-tint90 px-5 py-2 text-android-title-small font-medium text-primary-tint40 ios:text-ios-caption ios:font-semibold"
>
  <p>{timePeriod}</p>
  {#if isFirstEntry}
    <p class="text-right">
      {isMcredit
        ? $t('invoices.mcredit_total') + ' ' + getCurrencyUnit()
        : getCurrencyUnit()}
    </p>
  {/if}
</div>
