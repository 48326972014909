<script lang="ts">
  import { t } from "svelte-i18n";
  import { PaymentStatus } from "../constants";

  export let size: 'lg' | 'sm' = 'sm';
  export let outline: boolean = false;
  export let type: PaymentStatus;

  // default for due
  let colorStyle= 'text-primary-tint90';
  let outlineStyle= 'border-text-primary-tint90';

  $: {
    if (type === PaymentStatus.OVERDUE) {
      colorStyle = 'text-red-base';
      outlineStyle = outline ? 'border-red-base' : 'bg-red-badge';
    }

    if (type === PaymentStatus.REPAID) {
      colorStyle = outline ? 'text-green-base' : 'text-semantic-success';
      outlineStyle = outline ? 'border-green-base' : 'bg-green-badge';
    }

    if (type === PaymentStatus.PARTIALLY_REPAID) {
      colorStyle = 'text-orange-base';
      outlineStyle = outline ? 'border-orange-base' : 'bg-orange-badge';
    }

    if (type === PaymentStatus.PENDING) {
      colorStyle = 'text-primary-tint40';
      outlineStyle = outline ? 'border-orange-base' : 'bg-primary-tint90';
    }
  }
</script>

<span
  data-cy="payment-status-badge"
  class={`inline-block rounded-2xl py-[2px] font-medium ios:font-semibold 
    ${size === 'sm' ? 
    'px-[6px] text-android-label-small ios:text-ios-caption' : 
    'px-[8px] text-android-label-large ios:text-ios-callout'} 
    ${colorStyle}
    ${outlineStyle}
    ${outline ? 'border-[1.5px]' : ''}`}
>
  {$t(`payment_status.${type}`).toLowerCase()}
</span>

