<script lang="ts">
  export let color: string;
  export let size: string;
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  width={size}
  height={size}
  viewBox="0 0 20 20"
  fill="none"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2.55273 4.47786C2.23632 4.47786 1.97982 4.73437 1.97982 5.05078V6.76953H18.0215V5.05078C18.0215 4.73437 17.765 4.47786 17.4486 4.47786H2.55273ZM19.1673 5.05078C19.1673 4.10154 18.3978 3.33203 17.4486 3.33203H2.55273C1.60349 3.33203 0.833984 4.10154 0.833984 5.05078V15.3633C0.833984 16.3125 1.60349 17.082 2.55273 17.082H17.4486C18.3978 17.082 19.1673 16.3125 19.1673 15.3633V5.05078ZM18.0215 7.91536H1.97982V15.3633C1.97982 15.6797 2.23632 15.9362 2.55273 15.9362H17.4486C17.765 15.9362 18.0215 15.6797 18.0215 15.3633V7.91536ZM4.27148 10.7799C4.27148 10.4635 4.52799 10.207 4.8444 10.207H11.1465C11.4629 10.207 11.7194 10.4635 11.7194 10.7799C11.7194 11.0964 11.4629 11.3529 11.1465 11.3529H4.8444C4.52799 11.3529 4.27148 11.0964 4.27148 10.7799Z"
    fill={color}
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.75065 13.1237C3.75065 12.7785 4.03047 12.4987 4.37565 12.4987H8.75065C9.09583 12.4987 9.37565 12.7785 9.37565 13.1237C9.37565 13.4689 9.09583 13.7487 8.75065 13.7487H4.37565C4.03047 13.7487 3.75065 13.4689 3.75065 13.1237Z"
    fill={color}
  />
</svg>
