import { posthog } from '../../plugins/posthog';
import { Action, Context, Target } from './analytics/keys';

export const trackAnalytics = (
  {
    action,
    category,
    target,
  }: { action: Action; category: Context; target: Target },
  options?: object
) => {
  const payload = options ?? {};

  // will generate something like this: invoices:details_button_click
  posthog.capture(`${category}:${target}_${action}`, payload);
};

export const passBackendTracking = (
  metric: 'responseInvoiceCount' | 'totalMRIRequestCount',
  options: object
) => posthog.capture(metric, options);

export enum EventsMapKeys {
  TAB_CLICK,
  OVERVIEW_CLICK,

  INVOICE_DATE_PICKER,
  PAYMENT_METHOD_FILTER,

  LOAD_MORE_INVOICES,

  PDF_VIEWER_SLIDE,

  BANK_SELECT,

  TRANSACTION_CLICK,

  CTA_CLICK, // generic for button clicks
  BACK_ARROW_CLICK,
  CLOSE_APP,
}

export const eventsMap: {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  [key in EventsMapKeys]: (value?: any, options?: object) => void;
} = {
  [EventsMapKeys.BACK_ARROW_CLICK]: (category: Context) => {
    const action = Action.Click;
    const target = Target.NavigateBack;
    trackAnalytics({ action, category, target });
  },
  [EventsMapKeys.BANK_SELECT]: (options) => {
    const action = Action.Click;
    const category = Context.Repayment;
    const target = Target.BankListItem;
    trackAnalytics({ action, category, target }, options);
  },
  [EventsMapKeys.CLOSE_APP]: (context: Context) => {
    const action = Action.Close;
    const category = context;
    const target = Target.WebView;
    trackAnalytics({ action, category, target });
  },
  [EventsMapKeys.CTA_CLICK]: ({
    category,
    target,
  }: {
    category: Context;
    target: Target;
  }) => {
    const action = Action.Click;
    trackAnalytics({ action, category, target });
  },
  [EventsMapKeys.INVOICE_DATE_PICKER]: (dateRange) => {
    const action = Action.Submit;
    const category = Context.Invoices;
    const target = Target.InvoiceDatePickerRange;
    trackAnalytics({ action, category, target }, { dateRange });
  },
  [EventsMapKeys.LOAD_MORE_INVOICES]: ({
    category,
    requestedPage,
  }: {
    category: Context;
    requestedPage: number;
  }) => {
    const action = Action.LoadMore;
    const target = Target.InvoicesOverview;
    trackAnalytics({ action, category, target }, { requestedPage });
  },
  [EventsMapKeys.OVERVIEW_CLICK]: (target, value = {}) => {
    const action = Action.Click;
    const category = Context.Invoices;
    trackAnalytics({ action, category, target }, value);
  },
  [EventsMapKeys.PAYMENT_METHOD_FILTER]: (options) => {
    const action = Action.Submit;
    const category = Context.Invoices;
    const target = Target.PaymentMethodFilter;
    trackAnalytics({ action, category, target }, { options });
  },
  [EventsMapKeys.PDF_VIEWER_SLIDE]: (page) => {
    const action = Action.View;
    const category = Context.Invoice;
    const target = Target.PdfNextPage;
    trackAnalytics({ action, category, target }, { page });
  },
  [EventsMapKeys.TAB_CLICK]: ({ currentTab, toTab }) => {
    const action = Action.Click;
    const category = currentTab === 1 ? Context.Invoices : Context.Mcredit;
    const target = toTab === 1 ? Target.InvoicesTab : Target.MCreditTab;
    trackAnalytics({ action, category, target });
  },
  [EventsMapKeys.TRANSACTION_CLICK]: ({
    context,
    target,
  }: {
    context: Context;
    target: Target;
  }) => {
    const action = Action.Click;
    const category = context;
    trackAnalytics({ action, category, target });
  },
};

export const trackEvents = (
  eventType: EventsMapKeys,
  // @ts-ignore - this is a wrapper functionality to track events so we have many values and types incoming, hence we will have "any" as a type here
  value?: any,
  options?: object
) => {
  const fn = eventsMap[eventType];
  return fn
    ? fn(value, options)
    : console.error('Unknown tracking event type:', eventType);
};
