import { writable } from 'svelte/store';
import type { BuonoVoucher, DummyInvoice } from '../lib/constants';

type RepaymentStore = {
  buonoVouchers: BuonoVoucher[];
  invoices: DummyInvoice[];
  repaymentAmount: string;
};

const createRepaymentStore = () => {
  const initialState: RepaymentStore = {
    repaymentAmount: '',
    invoices: [],
    buonoVouchers: [],
  };
  const { subscribe, update } = writable(initialState);

  return {
    setRepaymentAmount: (value: string) =>
      update((state) => ({ ...state, repaymentAmount: value })),
    setInvoicesWithVouchers: (
      invoices: DummyInvoice[],
      buonoVouchers: BuonoVoucher[]
    ) => {
      let repaymentAmount = invoices.reduce(
        (sum, { amount }) => sum + amount,
        0
      );
      repaymentAmount += buonoVouchers.reduce(
        (sum, { amount }) => sum + amount,
        0
      );
      update((state) => ({
        ...state,
        invoices,
        buonoVouchers,
        repaymentAmount: repaymentAmount.toFixed(2),
      }));
    },
    subscribe,
  };
};

export const repaymentStore = createRepaymentStore();
