<script lang="ts">
  import Checkbox from '../Checkbox.svelte';

  export let isSelected: boolean = false;
  export let onChange: (isChecked: boolean) => void;
</script>

<li data-cy="selectable-list-item" class="flex flex-row items-center gap-3">
  <Checkbox isChecked={isSelected} {onChange} />
  <div class="flex-grow">
    <slot />
  </div>
</li>
