import { writable } from 'svelte/store';
import { nativeFn, supportsNativeFn } from './callNative';
import { navigateBack } from './navigationController';

let loaderTimeout: null | number = null;

const loaderStore = writable<number[]>([]);

function hideLoader() {
  loaderStore.update((currentLoaders) => {
    const updatedLoaders = currentLoaders.slice(0, -1);

    if (updatedLoaders.length === 0 && supportsNativeFn('hideSystemLoader')) {
      nativeFn.hideSystemLoader();
    }

    resetLoaderTimeout();

    return updatedLoaders;
  });
}

function resetLoaderTimeout() {
  if (loaderTimeout) {
    clearTimeout(loaderTimeout);
  }
  loaderTimeout = setTimeout(() => {
    loaderStore.set([]);
    // taking the user back in case of "problems" - loader is visible for 15 seconds.
    navigateBack();
  }, 15000);
}

function showLoader() {
  loaderStore.update((currentLoaders) => {
    const updatedLoaders = [...currentLoaders, Date.now()];

    if (updatedLoaders.length > 0 && supportsNativeFn('showSystemLoader')) {
      nativeFn.showSystemLoader();
    }

    resetLoaderTimeout();

    return updatedLoaders;
  });
}

loaderStore.subscribe((loaders) => {
  if (loaders.length === 0 && loaderTimeout) {
    clearTimeout(loaderTimeout);
    loaderTimeout = null;
  }
});

export { hideLoader, showLoader };
