<script lang="ts">
  import { onMount } from 'svelte';
  import { TARGET } from '../../env';
  import { getPlatformInfo, type PlatformInfo } from '../utils/platformInfo';

  export let variant: 'column' | 'dialog' = 'column';

  let platformInfo: PlatformInfo = {
    operatingSystem: 'loading',
    platform: 'loading',
    webVersion: 'loading',
    webBuild: 'loading',
  };

  onMount(async () => {
    platformInfo = await getPlatformInfo();

    platformInfo.webBuild = platformInfo.webBuild?.substring(0, 7);
  });
</script>

<section
  class="flex {variant === 'dialog'
    ? 'absolute bottom-1 left-1/2 -translate-x-1/2 flex-row p-0 text-[10px]'
    : 'flex-col items-center p-4 text-xs'} gap-2 text-primary-tint80"
>
  <p>Web v{platformInfo.webVersion} #{platformInfo.webBuild} ({TARGET})</p>

  {#if platformInfo.platform === 'web-view'}
    <p>Native v{platformInfo.nativeVersion} #{platformInfo.nativeBuildNumber} ({platformInfo.nativeFlavor})</p>
  {/if}
</section>
