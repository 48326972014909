import { nativeFn, supportsNativeFn } from '../JSBridge/callNative';
import { isAndroidNative, isIosNative, isNative } from './detectPlatform';

export type PlatformInfo = {
  nativeBuildNumber?: string;
  nativeFlavor?: string;
  nativeVersion?: string;
  operatingSystem: string;
  platform: string;
  webBuild?: string;
  webVersion?: string;
};

export function getOperatingSystem(): string {
  if (isIosNative()) {
    return 'ios-native';
  } else if (isAndroidNative()) {
    return 'android-native';
  }

  return 'other';
}

export function getPlatform(): string {
  return isNative() ? 'web-view' : 'web-browser';
}

export async function getPlatformInfo(): Promise<PlatformInfo> {
  const info: PlatformInfo = {
    operatingSystem: getOperatingSystem(),
    platform: getPlatform(),
    webBuild: import.meta.env.VITE_GIT_SHA || 'Unknown',
    webVersion: import.meta.env.VITE_PACKAGE_VERSION || 'Unknown',
  };

  if (
    supportsNativeFn('getAppBuildNumber') &&
    supportsNativeFn('getAppVersion') &&
    supportsNativeFn('getFlavor')
  ) {
    info.nativeBuildNumber = await nativeFn.getAppBuildNumber();
    info.nativeVersion = await nativeFn.getAppVersion();
    info.nativeFlavor = await nativeFn.getFlavor();
  }

  return info;
}
