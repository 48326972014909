<script lang="ts">
  import { onMount } from 'svelte';
  import { t } from 'svelte-i18n';
  import { navigate } from 'svelte-routing';
  import { MPAY_API_BASE_URI } from '../../env';
  import { hideLoader, showLoader } from '../../lib/JSBridge/loaderController';
  import { posthog } from '../../plugins/posthog';
  import { featureFlagsStore } from '../../store/featureFlagsStore';
  import { settingsStore } from '../../store/settingsStore';
  import { topBar } from '../../store/TopBarStore';
  import BankListItem from '../components/BankListItem.svelte';
  import SearchInput from '../components/SearchInput.svelte';
  import { type BankName, MPayApi } from '../constants';
  import { navigateBack } from '../JSBridge/navigationController';
  import Layout from '../layouts/main.svelte';
  import { Context } from '../utils/analytics/keys';
  import { banks } from '../utils/banks';
  import { sendRequest } from '../utils/fetch';
  import { routeBuilders } from '../utils/routeBuilders';
  import { EventsMapKeys, trackEvents } from '../utils/tracking';

  let searchValue = '';
  let filteredBanks = banks;

  const hasInterfaceOptions = (bankName: BankName) => {
    const bank = banks.find((item) => item.name === bankName);
    return bank && bank.bankInterfaces.length > 1;
  };

  const searchBank = (bankKey: string, query: string): boolean => {
    const translatedBankName = $t(`banks.${bankKey}`);

    const normalizedBankName = translatedBankName.toLowerCase();
    const normalizedQuery = query.toLowerCase().trim();

    return normalizedBankName.includes(normalizedQuery);
  };

  const onSelect = async (bankName: BankName) => {
    // If the bank has retail/corporate options, navigate to BankSelectionType
    if (hasInterfaceOptions(bankName)) {
      return navigate(
        routeBuilders.repaymentBankSelectionType({ bank: bankName })
      );
    }

    showLoader();
    trackEvents(EventsMapKeys.BANK_SELECT, { bank: bankName });

    try {
      await sendRequest<{ selectedBank: BankName }>(
        `${MPAY_API_BASE_URI}/${MPayApi.PROFILES}/${$settingsStore.credentials.accountId}`,
        {
          body: JSON.stringify({
            selectedBank: bankName,
          }),
          method: 'POST',
        }
      );
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }

    navigateBack();
  };

  const onSearch = (value: string) => {
    searchValue = value;
    if (value.trim() === '') {
      filteredBanks = banks;
    } else {
      filteredBanks = banks.filter((bank) => searchBank(bank.name, value));
    }
  };

  onMount(() => {
    if (!$featureFlagsStore.repayment) {
      return navigateBack();
    }
    posthog.capture('$pageview');

    topBar.setTitle($t('bank_selection.title'));
    topBar.setContext(Context.BankSelection);
    topBar.setShowBackButton(true);
  });
</script>

<Layout>
  <div class="px-5 pb-6 pt-8">
    <h1
      class="mb-6 text-android-headline-small font-medium text-primary-base ios:text-ios-title1 ios:font-semibold"
    >
      {$t('bank_selection.header')}
    </h1>

    <SearchInput
      id="bank-search"
      label={$t('bank_selection.search')}
      value={searchValue}
      onChange={onSearch}
    />
  </div>

  <ul>
    {#each filteredBanks as bank}
      <BankListItem name={bank.name} logo={bank.logo} onClick={onSelect} />
    {/each}
  </ul>
</Layout>
