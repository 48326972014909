import { isAndroidNative, isIosNative } from '../utils/detectPlatform';

export const nativeFn = {
  closeWebView: () => callNative('closeWebView'),
  getAccountCountryCode: (): Promise<string> =>
    callNative('getAccountCountryCode'),
  getAccountID: (): Promise<string> => callNative('getAccountID'),
  getDeeplinkPathPrefix: (): Promise<string> =>
    callNative('getDeeplinkPathPrefix'),
  getAppBuildNumber: (): Promise<string> => callNative('getAppBuildNumber'),
  getAppVersion: (): Promise<string> => callNative('getAppVersion'),
  getFlavor: (): Promise<
    'beta' | 'dev' | 'nightly' | 'production' | 'staging'
  > => callNative('getFlavor'),
  hideSystemLoader: () => callNative('hideSystemLoader'),
  openURL: (url: string): Promise<boolean> => callNative('openURL', url),
  showSystemLoader: () => callNative('showSystemLoader'),
} as const;

type NativeFnName = keyof typeof nativeFn;

async function callNative<R, F extends NativeFnName>(
  functionName: F,
  arg: string | undefined = undefined
): Promise<R> {
  if (isIosNative()) {
    return await window.webkit.messageHandlers[functionName].postMessage(
      arg ?? ''
    );
  }

  if (isAndroidNative()) {
    return new Promise((resolve) => {
      resolve(
        arg === undefined
          ? window.MPay[functionName]()
          : window.MPay[functionName](arg)
      );
    });
  }

  throw new Error(
    `Not supported on this platform. Tried to call native function: ${functionName}`
  );
}

export function supportsNativeFn(functionName: NativeFnName): boolean {
  return (
    window.MPay?.[functionName] !== undefined ||
    window.webkit?.messageHandlers[functionName] !== undefined
  );
}
