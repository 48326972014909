<script lang="ts">
  import { t } from 'svelte-i18n';
  import type { Invoice, PodInvoice } from '../../types/mpay.types';
  import { CustomerInvoiceType } from '../constants';

  export let invoice: Invoice | PodInvoice;
  export let forcePodTitle = false;

  $: getInvoiceType = () => {
    if (
      forcePodTitle ||
      ('customerInvoiceType' in invoice &&
        invoice.customerInvoiceType === CustomerInvoiceType.POD)
    ) {
      return 'general.pod';
    }

    if (
      'customerInvoiceType' in invoice &&
      invoice.customerInvoiceType === CustomerInvoiceType.COD
    ) {
      return 'general.cod';
    }

    return 'invoice.title';
  };
</script>

<time
  class="mb-0.5 text-android-title-large text-primary-base ios:text-ios-title2 ios:font-semibold"
>
  {invoice.dateToDisplay}
</time>
<p class="text-android-body-medium text-primary-base ios:text-ios-callout">
  {$t(getInvoiceType())}
  {invoice.number}
</p>
