<script lang="ts">
  import type { IconName } from '../../types/icons.types';
  import Icon from './Icon.svelte';

  export let icon: IconName;
  export let content: string;
</script>

<div
  class="flex items-center gap-3 rounded-2xl bg-blue-tint90 px-3 py-4 text-blue-base"
>
  <div class="flex-shrink-0">
    <Icon iconName={icon} size="16" />
  </div>
  <p class="text-android-body-small ios:text-ios-footnote">
    {content}
  </p>
</div>
