<script lang="ts">
  export let color: string;
  export let size: string;
</script>

<svg
  width={size}
  height={size}
  fill={color}
  viewBox="0 0 19 19"
  xmlns="http://www.w3.org/2000/svg"
  data-cy="check-small-icon"
>
  <path
    d="M5.25914 9.63477C4.89963 9.21552 4.26832 9.16709 3.84907 9.5266C3.42982 9.88611 3.38139 10.5174 3.7409 10.9367L6.6409 13.651C7.03226 14.1074 7.73498 14.1177 8.13962 13.6731L15.2396 6.67305C15.6113 6.26458 15.5815 5.63211 15.1731 5.2604C14.7646 4.88869 14.1321 4.91849 13.7604 5.32697L7.42228 11.4898L5.25914 9.63477Z"
    fill={color}
  />
</svg>
