import { routes } from '../../routes';
/* eslint-disable perfectionist/sort-modules */
type Routes = Record<string, { path: string }>;

// prettier-ignore
type ParamNames<Path extends string> =
  Path extends `${string}:${infer Param}/${infer Rest}` ? Param | ParamNames<Rest> :
  Path extends `${string}*/${infer Rest}` ? '*' | ParamNames<Rest> :
  Path extends `${string}:${infer Param}` ? Param :
  Path extends `${string}*` ? '*' :
  never;

type RouteParams<
  Path extends string,
  Name extends string = ParamNames<Path>,
> = Name extends never
  ? never
  : { [Key in Exclude<Name, '*'>]: string } & {
      [Key in Extract<Name, '*'>]?: string;
    };

type RoutesWithParams<R extends Routes> = {
  [Key in keyof R]: RouteParams<R[Key]['path']>;
};

type RouteBuilders<R extends Routes, P = RoutesWithParams<R>> = {
  [K in keyof P]: P[K] extends never
    ? (params?: Record<string, string>) => string
    : (params: P[K] & Record<string, string>) => string;
};
/* eslint-enable perfectionist/sort-modules */

export function createRouteBuilders<R extends Routes>(
  routes: R
): RouteBuilders<R> {
  const routeNames = Object.keys(routes) as (keyof R)[];

  return routeNames.reduce((acc, key) => {
    const builder = (params: Record<string, string> = {}) => {
      const pathTemplate = routes[key].path;
      const namedParameters = (pathTemplate.match(/:[a-zA-Z0-9]+/g) || []).map(
        (param) => param.slice(1)
      );
      const searchParams = Object.keys(params).filter(
        (param) => !namedParameters.includes(param) && param !== '*'
      );

      let path = namedParameters.reduce((acc, param) => {
        return acc.replace(`:${param}`, params[param]);
      }, pathTemplate);
      path = path.replace('*', params['*'] ?? '');
      if (searchParams.length === 0) return path;

      const search = new URLSearchParams();
      searchParams.forEach((param) => {
        search.set(param, params[param]);
      });

      return `${path}?${search.toString()}`;
    };

    return {
      ...acc,
      [key]: builder,
    };
  }, {} as RouteBuilders<R>);
}

export const routeBuilders = createRouteBuilders(routes);
