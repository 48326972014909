<script lang="ts">
  export let color: string;
  export let size: string;
</script>

<svg
  width={size}
  height={size}
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M0 10.0036C0 9.65838 0.279822 9.37856 0.625 9.37856H13.75C14.0952 9.37856 14.375 9.65838 14.375 10.0036C14.375 10.3487 14.0952 10.6286 13.75 10.6286H0.625C0.279822 10.6286 0 10.3487 0 10.0036Z"
    fill={color}
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.1831 6.43662C10.4271 6.19254 10.8229 6.19254 11.0669 6.43662L14.1919 9.56162C14.436 9.80569 14.436 10.2014 14.1919 10.4455L11.0669 13.5705C10.8229 13.8146 10.4271 13.8146 10.1831 13.5705C9.93898 13.3264 9.93898 12.9307 10.1831 12.6866L12.8661 10.0036L10.1831 7.3205C9.93898 7.07642 9.93898 6.68069 10.1831 6.43662Z"
    fill={color}
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M6.86126 1.41646C8.78978 0.571804 10.9465 0.400879 12.984 0.931218C15.0214 1.46156 16.8211 2.66229 18.0931 4.34C19.365 6.01771 20.0353 8.07474 19.9958 10.1797C19.9563 12.2848 19.2094 14.3152 17.8754 15.944C16.5414 17.5729 14.698 18.7052 12.642 19.1588C10.5861 19.6123 8.43731 19.3606 6.54183 18.4443C4.64635 17.5279 3.11451 16.0001 2.19304 14.1071C2.04197 13.7967 2.17109 13.4227 2.48145 13.2716C2.79182 13.1205 3.16588 13.2497 3.31696 13.56C4.11556 15.2006 5.44316 16.5247 7.08591 17.3189C8.72866 18.1131 10.5909 18.3312 12.3727 17.9381C14.1546 17.5451 15.7522 16.5637 16.9083 15.152C18.0645 13.7404 18.7118 11.9806 18.746 10.1563C18.7802 8.33196 18.1994 6.54921 17.097 5.09519C15.9946 3.64117 14.4349 2.60054 12.6691 2.14091C10.9033 1.68128 9.03413 1.82942 7.36275 2.56145C5.69137 3.29349 4.31505 4.56681 3.45547 6.17632C3.29286 6.4808 2.91421 6.5958 2.60974 6.4332C2.30526 6.27059 2.19025 5.89194 2.35286 5.58746C3.34468 3.73034 4.93275 2.26112 6.86126 1.41646Z"
    fill={color}
  />
</svg>
