import { jwtDecode } from 'jwt-decode';
import { posthog } from '../../plugins/posthog';
import { settingsStore } from '../../store/settingsStore';
import { ACCOUNT_ID_ITEM } from '../constants';
import { nativeFn, supportsNativeFn } from '../JSBridge/callNative';
import { ResetApplicationException } from './fetch';
import { ensureIdamSupportsCountryCode, getIdamUrl } from './getIdamUrl';
import {
  getStoredAccountId,
  getStoredCountryCode,
  getStoredToken,
  storeAccountId,
  storeCountryCode,
} from './localStorage';
import { AppLocation } from './location';

export const isValidToken = (token: string): boolean => {
  try {
    const userData: { exp: number } = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);

    return userData.exp > currentTime;
  } catch {
    return false;
  }
};

export const areCredentialsValid = (): boolean => {
  const token = getStoredToken();
  const accountId = getStoredAccountId();

  return !!accountId && !!token && isValidToken(token);
};

export const getCaseInsensitiveSearchParam = (
  searchParams: URLSearchParams,
  param: string
): string | undefined => {
  return [...searchParams.entries()]
    .find(([key]) => key.toLowerCase() === param)
    ?.at(1);
};

export const getAccountIdFromNative = async (): Promise<string | undefined> => {
  if (supportsNativeFn('getAccountID')) {
    return (await nativeFn.getAccountID()) || undefined;
  } else {
    return undefined;
  }
};

export const getCountryCodeFromNative = async (): Promise<
  string | undefined
> => {
  if (supportsNativeFn('getAccountCountryCode')) {
    return (await nativeFn.getAccountCountryCode()) || undefined;
  } else {
    return undefined;
  }
};

// Authenticates the logged in user in an in-app browser flow based on credentials passed from the app
export const authenticateUser = async (): Promise<void> => {
  const authUrl = new URL(window.location.href);
  const searchParams = authUrl.searchParams;

  // check for authentication errors in the URL
  if (searchParams.has('error')) {
    throw new Error(
      'Authentication error in url ' + searchParams.get('error_description')
    );
  }

  let accountId = await getAccountIdFromNative();
  accountId ||= getCaseInsensitiveSearchParam(
    searchParams,
    ACCOUNT_ID_ITEM.toLowerCase()
  );
  if (accountId) {
    storeAccountId(accountId);
  }
  accountId ||= getStoredAccountId() ?? undefined;

  let countryCode = await getCountryCodeFromNative();
  countryCode ||= getCaseInsensitiveSearchParam(searchParams, 'country');
  if (countryCode) {
    storeCountryCode(countryCode);
  }
  countryCode ||= getStoredCountryCode() ?? undefined;

  const token = getStoredToken();

  // check if local storage credentials are valid
  if (accountId && token && isValidToken(token)) {
    settingsStore.setCredentials({ accountId, token });

    return;
  }

  // handle search parameters in the URL before IDAM redirect

  const idamSupportedCountryCode = ensureIdamSupportsCountryCode(countryCode);
  if (accountId && idamSupportedCountryCode) {
    const idamUrl = getIdamUrl(
      idamSupportedCountryCode,
      accountId,
      window.location.pathname
    );
    posthog.capture('reset_application');
    AppLocation.replace(idamUrl);
    throw new ResetApplicationException();
  }

  throw new Error('Authentication failed');
};
