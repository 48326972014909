<script lang="ts">
  import { t } from 'svelte-i18n';
  import { colors } from '../../assets/styles/cssColors';
  import { fetchAccountDetails } from '../../store/settingsStore';
  import Layout from '../layouts/main.svelte';
  import { authenticateUser } from '../utils/auth';
  import { ResetApplicationException } from '../utils/fetch';
  import { setPosthogUniqueId } from '../utils/uniqueIdentifier';
  import EmptyScreen from './EmptyScreen.svelte';
  import PlatformInfo from './PlatformInfo.svelte';

  const performAuthentication = async () => {
    try {
      await authenticateUser();
      await fetchAccountDetails();
      await setPosthogUniqueId();

      // Set the background color after successful initialization
      document.body.style.background = colors.semantic.appBackground;
      return true;
    } catch (err) {
      if (err instanceof ResetApplicationException) {
        console.info('Reset application due to outdated credentials');
        return false;
      } else {
        console.error('Error during initialization:', err);
        throw err;
      }
    }
  };

  const authPromise = performAuthentication();
</script>

{#await authPromise}
  <div></div>
{:then authenticated}
  {#if authenticated}
    <slot />
  {:else}
    <div></div>
  {/if}
{:catch}
  <Layout>
    <div class="mt-6">
      <EmptyScreen label={$t('error.auth_failure')} />

      <PlatformInfo />
    </div>
  </Layout>
{/await}
