import { posthog, PostHogPluginMissingError } from '../../plugins/posthog';
import { featureFlagsStore } from '../../store/featureFlagsStore';

export class PostHogFeatureFlagsError extends Error {
  constructor() {
    super('PostHog onFeatureFlags error');
    this.name = 'PostHogFeatureFlagsError';
  }
}

export const featureFlagsNames = {
  debit_period_next_due_date: 'debit_period_next_due_date',
  due_overdue_amount: 'due_overdue_amount',
  invoice_notice_banner: 'invoice_notice_banner',
  payment_status: 'payment_status',
  repayment: 'repayment',
  repayment_select_invoices: 'repayment_select_invoices',
};

export function initFeatureFlags(): Promise<void> {
  return new Promise((resolve, reject) => {
    if (!posthog) {
      reject(new PostHogPluginMissingError());
      return;
    }
    if (!posthog.onFeatureFlags) {
      reject(new PostHogFeatureFlagsError());
      return;
    }

    posthog.onFeatureFlags(() => {
      const flags = {
        debit_period_next_due_date:
          posthog.isFeatureEnabled(
            featureFlagsNames.debit_period_next_due_date
          ) ?? false,
        due_overdue_amount:
          posthog.isFeatureEnabled(featureFlagsNames.due_overdue_amount) ??
          false,
        invoice_notice_banner:
          posthog.isFeatureEnabled(featureFlagsNames.invoice_notice_banner) ??
          false,
        payment_status:
          posthog.isFeatureEnabled(featureFlagsNames.payment_status) ?? false,
        repayment:
          posthog.isFeatureEnabled(featureFlagsNames.repayment) ?? false,
        repayment_select_invoices:
          posthog.isFeatureEnabled(
            featureFlagsNames.repayment_select_invoices
          ) ?? false,
      };
      featureFlagsStore.setFeatureFlags(flags);

      resolve();
    });
  });
}
