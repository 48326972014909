<script lang="ts">
  import { onMount } from 'svelte';
  import { t } from 'svelte-i18n';
  import { Link } from 'svelte-routing';
  import type { IconName } from '../../types/icons.types';
  import type { Invoice, Payment } from '../../types/mpay.types';
  import { CustomerInvoiceType, PaymentStatus } from '../constants';
  import { paymentCodes } from '../constants';
  import Badge from './Badge.svelte';
  import Icon from './Icon.svelte';
  import ListInvoiceAmount from './ListInvoiceAmount.svelte';

  export let invoice: Invoice;
  export let href: string = '';
  export let isMcreditList = false;
  export let onClick: () => void;

  let name: string = '';
  let iconName: IconName;

  const paymentCodeToIconMap: Record<string, IconName> = {
    // Cash
    [paymentCodes[1]]: 'CashPaymentCoins',
    // Payment Order
    [paymentCodes[206]]: 'PaymentOrder',
    // Cards
    [paymentCodes[300]]: 'Card',
    [paymentCodes[301]]: 'Card',
    [paymentCodes[400]]: 'Card',
    // MCredit
    [paymentCodes[600]]: 'Flex',
    // Vouchers
    [paymentCodes[800]]: 'Voucher',
    [paymentCodes[801]]: 'Voucher',
    [paymentCodes[810]]: 'Voucher',
    [paymentCodes[811]]: 'Voucher',
    [paymentCodes[903]]: 'Voucher',
    [paymentCodes[904]]: 'Voucher',
    [paymentCodes[905]]: 'Voucher',
    [paymentCodes[1000]]: 'Voucher',
  };

  const getIconName = (paymentList: Payment[]): IconName => {
    if (paymentList.length > 1) {
      return 'MultiplePayments';
    }
    return (
      paymentCodeToIconMap[paymentList[0]?.paymentCode.toString()] ||
      'DefaultPayment'
    );
  };

  onMount(() => (iconName = getIconName(invoice.paymentList)));

  $: showMcreditStaus =
    isMcreditList &&
    invoice.mCreditPaymentStatus &&
    invoice.mCreditPaymentStatus !== PaymentStatus.DUE;
  $: isFsd =
    invoice.customerInvoiceType === CustomerInvoiceType.POD ||
    invoice.customerInvoiceType === CustomerInvoiceType.COD;
  $: name = invoice.cardHolder?.firstName + ' ' + invoice.cardHolder?.lastName;
</script>

<li
  class="border-b border-primary-tint90 px-5 last:border-0 active:bg-primary-tint90"
  data-cy="invoices-list-item"
>
  <Link
    to={href}
    on:click={onClick}
    class="grid grid-cols-[40px_minmax(0,1fr)_80px] items-center gap-3 pb-[15px] pt-[17px]"
  >
    <div
      class="flex h-[40px] w-[40px] items-center justify-center rounded-lg bg-semantic-inverted p-2 text-semantic-neutral shadow-small"
    >
      <Icon {iconName} />
    </div>

    <div class="w-max-1/2 flex w-full flex-col">
      <div class="flex items-center">
        <time
          datetime={invoice.dateToDisplay}
          class="mr-2 text-android-title-medium text-primary-base ios:text-ios-headline ios:font-semibold"
        >
          {invoice.dateToDisplay}
        </time>

        {#if showMcreditStaus}
          <Badge type={invoice.mCreditPaymentStatus} />
        {/if}
      </div>

      <div class="flex">
        <p
          class="mr-1 truncate text-android-body-small text-primary-base ios:text-ios-footnote"
        >
          {$t(`invoice_types.${invoice.invoiceType}`)}
        </p>
        <p
          class="truncate text-android-body-small text-primary-tint40 ios:text-ios-footnote"
        >
          {name}
        </p>
      </div>
    </div>

    <div class="flex flex-col items-end justify-end">
      <!-- MCREDIT TAB: showing only MCredit amounts -->
      {#if isMcreditList}
        <ListInvoiceAmount
          isMain
          amount={invoice.paymentList.find((item) => item.paymentCode === 600)
            ?.paymentAmount || null}
        />
        <!-- FSD (POD+COD) - no net value, instead showing icon+label -->
        {#if isFsd}
          <span
            class="flex gap-1 text-android-body-small text-primary-tint40 ios:text-ios-footnote"
          >
            <Icon iconName="Delivery" size="16" />
            {$t('invoice.delivery')}
          </span>

          <!-- Multiple payments - no mcredit net value, instead showing icon+label -->
        {:else if invoice.paymentList.length > 1}
          <span
            class="flex gap-1 truncate text-android-body-small text-primary-tint40 ios:text-ios-footnote"
          >
            <Icon iconName="StoreMakro" size="16" />
            {$t('invoice.in_store')}
          </span>

          <!-- MCredit only payments - show available net value -->
        {:else}
          <ListInvoiceAmount amount={invoice.netAmount} type={'netto'} />
        {/if}

        <!-- INVOICES TAB -->
      {:else}
        <ListInvoiceAmount
          isMain
          amount={invoice.grossAmount}
          type={invoice.grossAmount < 0 ? 'voucher' : 'total'}
        />

        <ListInvoiceAmount amount={invoice.netAmount} type={'netto'} />
      {/if}
    </div>
  </Link>
</li>
