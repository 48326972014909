<script lang="ts">
  import type { SvelteComponent } from 'svelte';
  import { Route } from 'svelte-routing';
  import AuthWrapper from './AuthWrapper.svelte';
  import Slot from './Slot.svelte';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  type ComponentConstructor = new (...args: any[]) => SvelteComponent;

  export let path: string;
  export let component: ComponentConstructor | null = null;
  export let beforeAuthWrapper: ComponentConstructor = Slot;
</script>

<Route {path} let:params let:location>
  <svelte:component this={beforeAuthWrapper}>
    <AuthWrapper>
      {#if component}
        <svelte:component this={component} />
      {:else}
        <slot {params} {location} />
      {/if}
    </AuthWrapper>
  </svelte:component>
</Route>
