<script lang="ts">
  import { isLoading } from 'svelte-i18n';
  import { colors } from '../../assets/styles/cssColors';
  import { dialogStore } from '../../store/dialogStore';
  import { topBar } from '../../store/TopBarStore';
  import { bodyScrollLock } from '../../store/UiStore';
  import Dialog from '../components/Dialog.svelte';
  import TopBar from '../components/TopBar.svelte';
  import { navigateBack } from '../JSBridge/navigationController';
  import { Context } from '../utils/analytics/keys';
  import { EventsMapKeys, trackEvents } from '../utils/tracking';

  $: {
    if (
      $topBar.context === Context.InvoiceDetails ||
      $topBar.context === Context.RepaymentDetails
    ) {
      document.body.style.background = colors.white;
    } else {
      document.body.style.background = colors.semantic.appBackground;
    }
  }

  $: {
    if ($bodyScrollLock) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }

  const onBackClick = () => {
    const eventKey =
      window.location.pathname === '/'
        ? EventsMapKeys.CLOSE_APP
        : EventsMapKeys.BACK_ARROW_CLICK;

    trackEvents(eventKey, $topBar.context);
    navigateBack();
  };
</script>

<!-- loader for the i18n-->
{#if $isLoading}
  <div></div>
{:else}
  <TopBar
    title={$topBar.title}
    showBackButton={$topBar.showBackButton}
    backButtonLabel={$topBar.backButtonLabel}
    onClickBackButton={onBackClick}
    button={$topBar.button}
  />

  <main class="pt-topBar">
    <slot />
  </main>

  <Dialog
    bind:showDialog={$dialogStore.showDialog}
    onPrimaryClick={$dialogStore.actions}
    showPlatformInfo
  />
{/if}
