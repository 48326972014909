<script lang="ts">
  import { t } from 'svelte-i18n';
  import { repaymentStore } from '../../store/repaymentStore';
  import {
    type BuonoVoucher,
    type DummyInvoice,
    PaymentStatus,
  } from '../constants';
  import { getDisplayDate, getFormattedNumber } from '../utils/formatters';
  import { getCurrencyUnit } from '../utils/helpers';
  import Alert from './Alert.svelte';
  import Badge from './Badge.svelte';
  import BuonoSelectDialog from './BuonoSelectDialog.svelte';
  import InvoicesListHeader from './InvoicesListHeader.svelte';
  import List from './List.svelte';
  import ListItemButton from './ListButtonItem.svelte';
  import ListInvoiceAmount from './ListInvoiceAmount.svelte';
  import ListItem from './ListItem.svelte';
  import SelectableList from './SelectableList/SelectableList.svelte';
  import SelectableListItem from './SelectableList/SelectableListItem.svelte';
  import TopBar from './TopBar.svelte';

  export let onClose: () => void;

  let showBuonoDialog = false;

  // Test data for displaying
  const currentDate = new Date();
  const twoDaysEarlier = new Date(currentDate);
  twoDaysEarlier.setDate(currentDate.getDate() - 2);
  const threeDaysLater = new Date(currentDate);
  threeDaysLater.setDate(currentDate.getDate() + 3);

  const invoices: DummyInvoice[] = [
    {
      id: '1',
      amount: 9674,
      currency: 'EUR',
      invoiceDate: twoDaysEarlier,
      dateToDisplay: getDisplayDate(twoDaysEarlier),
      overdue: false,
    },
    {
      id: '2',
      amount: 5000,
      currency: 'EUR',
      invoiceDate: threeDaysLater,
      dateToDisplay: getDisplayDate(threeDaysLater),
      overdue: true,
    },
  ];

  const buonoEnabled = true;
  // End of test data for displaying

  const currency = getCurrencyUnit();

  let selectedInvoices: DummyInvoice[] = $repaymentStore.invoices;
  let selectedVauchers: BuonoVoucher[] = $repaymentStore.buonoVouchers;
  $: invoicesSummary = selectedInvoices.reduce(
    (sum, { amount }) => sum + amount,
    0
  );
  $: vauchersTotalAmount = selectedVauchers.reduce(
    (sum, { amount }) => sum + amount,
    0
  );
  $: totalRepaymentAmount = invoicesSummary + vauchersTotalAmount;

  const onAddInvoiceToSelected = (invoice: DummyInvoice) => {
    const id = selectedInvoices.findIndex(({ id }) => id == invoice.id);
    if (id === -1) {
      selectedInvoices = [...selectedInvoices, invoice];
    }
  };

  const onRemoveInvoiceFromSelected = (invoice: DummyInvoice) => {
    selectedInvoices = selectedInvoices.filter(({ id }) => id !== invoice.id);
  };

  const onSave = () => {
    repaymentStore.setInvoicesWithVouchers(selectedInvoices, selectedVauchers);
    onClose();
  };
</script>

<div
  data-cy="repayment-invoice-selector"
  class="h-full w-full bg-semantic-appBackground"
>
  <TopBar
    title={$t('repayment.title')}
    showBackButton
    backButtonLabel={$t('general.cta.cancel')}
    onClickBackButton={onClose}
    button={{
      label: $t('general.cta.save'),
      onClick: onSave,
    }}
  />
  <div class="pt-topBar">
    <div class="px-5 pt-8">
      <h1
        class="mb-4 text-android-headline-small font-medium text-primary-base ios:text-ios-title1 ios:font-semibold"
      >
        {$t('repayment_select_invoices.header')}
      </h1>

      <div data-cy="summary-list" class="mb-3">
        <List>
          <ListItem
            label={$t('repayment_select_invoices.invoices_selected_label', {
              values: { count: selectedInvoices.length },
            })}
            value={`${getFormattedNumber(invoicesSummary)} ${currency}`}
          />
          {#if vauchersTotalAmount !== 0}
            <ListItem
              label={$t('repayment_select_invoices.buono_metro_pro')}
              value={`${getFormattedNumber(vauchersTotalAmount)} ${currency}`}
              variant="accent-value"
            />
          {/if}
          <ListItem
            label={$t('repayment_select_invoices.total_amount')}
            value={`${getFormattedNumber(totalRepaymentAmount)} ${currency}`}
            variant="accent"
          />
          <ListItemButton
            label="Apply METRO Buono"
            icon="ArrowRight"
            onClick={() => (showBuonoDialog = true)}
            disabled={!buonoEnabled}
          />
        </List>
      </div>

      <div class="mb-5">
        <Alert
          icon="AlertInfo"
          content={$t('repayment_select_invoices.alert')}
        />
      </div>

      <InvoicesListHeader
        leftLabel={$t('repayment_select_invoices.invoices_select_list_header')}
        rightLabel={currency}
      />
      <SelectableList>
        {#each invoices as invoice}
          <SelectableListItem
            isSelected={selectedInvoices.some(({ id }) => id === invoice.id)}
            onChange={(selected) =>
              selected
                ? onAddInvoiceToSelected(invoice)
                : onRemoveInvoiceFromSelected(invoice)}
          >
            <div class="flex flex-row justify-between py-4 text-primary-base">
              <div class="flex flex-row items-center gap-1.5">
                <time datetime={invoice.dateToDisplay}>
                  {invoice.dateToDisplay}
                </time>
                {#if invoice.overdue}
                  <Badge type={PaymentStatus.OVERDUE} />
                {/if}
              </div>
              <ListInvoiceAmount type="total" isMain amount={invoice.amount} />
            </div>
          </SelectableListItem>
        {/each}
      </SelectableList>
    </div>

    <BuonoSelectDialog
      bind:showDialog={showBuonoDialog}
      onSelect={(selected) => (selectedVauchers = selected)}
    />
  </div>
</div>
