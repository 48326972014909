<script context="module" lang="ts">
  type ListItemVariant = 'default' | 'accent' | 'accent-value';
</script>

<script lang="ts">
  export let variant: ListItemVariant = 'default';
  export let label: string;
  export let value: string;

  const getLabelStyle = (variant: ListItemVariant) => {
    switch (variant) {
      case 'accent':
        return 'font-semibold';
      default:
        return '';
    }
  };

  const getValueStyle = (variant: ListItemVariant) => {
    switch (variant) {
      case 'accent-value':
        return 'font-semibold text-blue-base';
      default:
        return 'font-semibold';
    }
  };
</script>

<li class="flex h-[52px] items-center justify-between">
  <span data-cy="list-item-label" class={getLabelStyle(variant)}>
    {label}
  </span>
  <span data-cy="list-item-value" class={getValueStyle(variant)}>
    {value}
  </span>
</li>
