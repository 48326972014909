<script lang="ts">
  import { onMount } from 'svelte';
  import { t } from 'svelte-i18n';
  import { navigate } from 'svelte-routing';
  import type { TransactionDetail } from '../../types/globals.types';
  import type { Repayment } from '../../types/mpay.types';
  import { posthog } from '../../plugins/posthog';
  import { mCreditStore } from '../../store/mCreditStore';
  import { topBar } from '../../store/TopBarStore';
  import Button from '../components/Button.svelte';
  import Icon from '../components/Icon.svelte';
  import NoticeBanner from '../components/NoticeBanner.svelte';
  import Layout from '../layouts/main.svelte';
  import { Context, Target } from '../utils/analytics/keys';
  import { getBank } from '../utils/banks';
  import { isAndroidNative } from '../utils/detectPlatform';
  import { getFormattedNumber } from '../utils/formatters';
  import { getCurrencyUnit } from '../utils/helpers';
  import { getRecordById } from '../utils/invoices';
  import { routeBuilders } from '../utils/routeBuilders';
  import { EventsMapKeys, trackEvents } from '../utils/tracking';

  export let id = '';
  let repayment: null | Repayment = null;
  let repaymentDetails: TransactionDetail[] = [];

  posthog.capture('$pageview');

  onMount(() => {
    repayment = getRecordById($mCreditStore.transactions, id);
    if (!repayment) return navigate(routeBuilders.mcredit({}));

    topBar.setContext(Context.RepaymentDetails);
    topBar.setTitle($t('repayment_details.details'));
    topBar.setShowBackButton(true);

    repaymentDetails = [
      {
        iconName: 'Invoices',
        label: $t('repayment_details.type'),
        testId: 'repayment-type',
        value: $t('repayment_details.repayment'),
      },
      {
        iconName: 'RepaymentStatus',
        label: $t('repayment_details.status'),
        testId: 'repayment-status',
        value: $t(`repayment_status.${repayment.status}`),
      },
      {
        iconName: 'CashPaymentCoins',
        label: $t('repayment_details.amount'),
        testId: 'repayment-amount',
        value:
          getFormattedNumber(Number(repayment.amount)) +
          ' ' +
          getCurrencyUnit(),
      },
      {
        iconName: 'Calendar',
        label: $t('repayment_details.timestamp'),
        testId: 'repayment-timestamp',
        value: repayment.dateToDisplay || '',
      },
      {
        iconName: 'Business', // just placeholder, template shows bank logo
        label: $t('repayment_details.bank'),
        testId: 'repayment-bank',
        value: $t(`banks.${repayment.bankId}`),
      },
      {
        iconName: 'SingleManCircle',
        label: $t('repayment_details.card_holder'),
        testId: 'repayment-card-holder',
        value: repayment.cardHolder,
      },
    ];
  });

  const goBack = () => {
    trackEvents(EventsMapKeys.CTA_CLICK, {
      category: $topBar.context,
      target: Target.BackToOverview,
    });
    window.history.back();
  };
</script>

<Layout>
  {#if repayment}
    {#if repayment.status === 'submitted'}
      <NoticeBanner noticeText={$t('repayment.pending_notice')} />
    {/if}
    <div class="my-4 px-5" data-cy="repayment-details-list">
      <section class="mb-4">
        <p
          class="py-2 text-android-label-small font-medium text-primary-tint40 ios:text-ios-caption ios:font-semibold"
        >
          {$t('repayment_details.title')}
        </p>

        <ul>
          {#each repaymentDetails as item, index}
            {@const isBankItem = index === 3}
            <li
              data-cy={item.testId || ''}
              class="flex gap-3 border-b border-primary-tint90 py-[14px] text-primary-base last:border-none"
            >
              {#if isBankItem}
                <div
                  class="flex h-[40px] w-[40px] items-center justify-center rounded-lg bg-semantic-appBackground p-2"
                >
                  <img
                    src={getBank(repayment.bankId)?.logo}
                    alt="logo"
                    height="20"
                    width="20"
                  />
                </div>
              {:else}
                <div class="rounded-lg bg-semantic-appBackground p-2.5">
                  <Icon iconName={item.iconName} />
                </div>
              {/if}

              <div class="inline-flex flex-col">
                <p
                  class="text-android-title-medium font-medium ios:text-ios-headline ios:font-semibold"
                  class:text-red-base={index === 1 &&
                    repayment.status === 'failed'}
                >
                  {item.value}
                </p>
                <p
                  class="text-android-body-small text-primary-tint40 ios:text-ios-footnote"
                >
                  {item.label}
                </p>
              </div>
            </li>
          {/each}
        </ul>
      </section>

      {#if isAndroidNative()}
        <div class="mb-6 mt-4 flex flex-col gap-3 px-4">
          <Button label={$t('buttons.back')} onClick={goBack} />
        </div>
      {/if}
    </div>
  {/if}
</Layout>
