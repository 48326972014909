<script lang="ts">
  import Icon from './Icon.svelte';

  export let isChecked: boolean;
  export let onChange: (isChecked: boolean) => void;

  const handleOnChange = (event: Event) => {
    const target = event.target as HTMLInputElement;
    onChange(target.checked);
  };
</script>

<span class="relative h-[18px] w-[18px]">
  {#if isChecked}
    <span
      class="absolute left-0 top-0 h-[18px] w-[18px] rounded-sm bg-blue-base text-white"
    >
      <Icon iconName="CheckSmall" size="18" />
    </span>
  {/if}
  <input
    type="checkbox"
    class="absolute left-0 top-0 h-[18px] w-[18px] appearance-none rounded-sm border border-blue-base text-blue-base"
    checked={isChecked}
    on:change={handleOnChange}
  />
</span>
